.div {
  position: relative;
  line-height: 125%;
  font-weight: 500;
}
.kfba {
  margin: 0;
}
.kfba10Container {
  position: relative;
  font-size: 20px;
  line-height: 175%;
  font-weight: 300;
  white-space: pre-wrap;
  display: inline-block;
  width: 710px;
}
.div1,
.div2 {
  position: relative;
  line-height: 150%;
  font-weight: 500;
}
.div2 {
  font-size: 24px;
  font-weight: 600;
  color: var(--gray-900);
}
.contents,
.title {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.title {
  font-size: var(--font-size-base);
  color: var(--color-darkslategray);
}
.contents {
  position: absolute;
  top: 202px;
  left: 970px;
  gap: 40px;
  font-size: 32px;
  font-family: var(--font-pretendard);
}
.logoBlackIcon {
  position: relative;
  width: 102px;
  height: 50px;
}
.home {
  position: relative;
  text-transform: uppercase;
}
.gnbBlack,
.menu {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.menu {
  justify-content: flex-start;
  gap: 56px;
}
.gnbBlack {
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(--color-white);
  width: 1920px;
  justify-content: space-between;
  padding: 16px var(--padding-221xl);
  box-sizing: border-box;
}
.associationPresident {
  position: relative;
  line-height: 100%;
  text-transform: capitalize;
  transform: rotate(-90deg);
  transform-origin: 0 0;
  top: 186px;
  left: 167px;
}
.imgAboutUsIcon {
  position: relative;
  width: 440px;
  height: 585px;
  object-fit: cover;
}
.imgAboutUs {
  position: absolute;
  top: 190px;
  left: 210px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-xs);
}
.kfbaIcon {
  position: relative;
  width: 70px;
  height: 34px;
}
.history {
  position: relative;
  line-height: 100%;
  text-transform: uppercase;
  font-weight: 600;
}
.menu1,
.menu2 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 38px;

  a {
    cursor: pointer;
    text-decoration: none;
    color: var(--color-gray-300);
  }
}
.menu1 {
  align-items: center;
  gap: 63px;
}
.kfba3 {
  color: var(--color-darkslategray);
}
.copyright2013Container,
.div3 {
  position: relative;
  line-height: 100%;
}
.div3 {
  text-decoration: underline;
  font-weight: 500;
}
.buttons {
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-xs);
  color: var(--gray-900);
  font-family: var(--font-pretendard);
}
.buttons,
.footer,
.info {
  display: flex;
  flex-direction: row;
}
.info {
  align-items: flex-end;
  justify-content: flex-start;
  gap: 19px;
  color: var(--gray-700);
}
.footer {
  position: absolute;
  bottom: 0;
  left: 0;
  box-sizing: border-box;
  width: 1920px;
  align-items: center;
  justify-content: space-between;
  padding: 20px var(--padding-221xl);
  font-size: var(--font-size-sm);
  border-top: 1px solid #ddd;
}

.pcAboutUs {
  position: relative;
  background-color: var(--color-white);
  width: 1920px;
  height: 921px;
  margin: 0 auto;
  left: 50%;
  transform: translateX(-50%);
  font-size: var(--font-size-base);
  color: var(--gray-900);
  font-family: var(--font-montserrat);
}
.pcAboutUsContainer {
  width: 1920px;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
}
@media screen and (min-width: 1480px) and (max-width: 1920px) {
  .pcAboutUs {
    width: 1920px;
    margin: 0 auto;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
  }
  .pcAboutUsContainer {
    width: 100%;
    position: relative;
    overflow: hidden;
  }
}
.footerBg {
  position: absolute;
  top: 846px;
  width: 100%;
  height: 1px;
  background-color: #ddd;
}
@media screen and (max-width: 1480px) {
  .pcAboutUs {
    width: 1920px;
    margin: 0 auto;
    /* margin-left: -220px; */
    position: relative;
    clip-path: inset(0 calc(220px) 0 calc(220px));
  }
  .pcAboutUsContainer {
    width: 1480px;
    margin: 0 auto;
    position: relative;
    overflow: hidden;
  }
  .footer {
    border-top: 1px solid #ddd;
  }
  .section01Bg,
  .section02Bg,
  .section03Bg,
  .footerBg {
    display: none;
  }
}

/* header */
.pcHeaderContainer {
  width: 100%;
  margin: 0 auto;
  position: fixed;
  overflow: hidden;
  top: 0;
  z-index: 10;
  height: 82px;
  color: var(--gray-900);
  transition: background-color 1s;
}
.pcHeader {
  font-size: var(--font-size-sm);
  font-family: var(--font-pretendard);
  width: 1920px;
  height: 82px;
  margin: 0 auto;
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;
}
.pcHeaderScrolled {
  background-color: white;
  color: var(--gray-900);
}
.gnbWhite {
  position: fixed;
  top: 0;
  left: 0;
  width: 1920px;
  justify-content: space-between;
  padding: var(--padding-base) var(--padding-221xl);
  box-sizing: border-box;
  font-size: var(--font-size-base);
  font-family: var(--font-montserrat);
  z-index: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.logoWhiteIcon {
  position: relative;
  width: 102px;
  height: 50px;
}
.menu {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 56px;
  a {
    color: inherit;
    text-decoration: none;
  }
}
.home {
  position: relative;
  text-transform: uppercase;
}
/* floatingButton */
.button,
.buttonKakaotalk {
  position: relative;
  width: 64px;
  height: 64px;
}
.buttonKakaotalk {
  width: 72px;
  height: 72px;
}
.floatingButton {
  position: fixed;
  bottom: 40px;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xs);
}
.bg4 {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 50%;
  background-color: var(--gray-900);
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.1);
  width: 64px;
  height: 64px;
}
.b1 {
  position: absolute;
  top: 25px;
  left: 7px;
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
  text-transform: uppercase;
}
.btnStyle {
  background: none;
  border: none;
  padding: 0;
}
@media screen and (min-width: 1480px) and (max-width: 1920px) {
  .pcHeader {
    width: 1920px;
    height: 100px;
    margin: 0 auto;
    position: fixed;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }
}
@media screen and (max-width: 1480px) {
  .pcHeader {
    width: 100%;
    margin: 0 auto;
  }
  .pcHeaderContainer {
    width: 100%;
  }

  .gnbWhite {
    width: 100%;
    padding: var(--padding-base) 20px;
    z-index: 10;
  }
}
