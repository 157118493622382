.newsWrapper {
  position: relative;
  padding-top: 200px;
  padding-bottom: 200px;
  color: var(--gray-900);
  width: 1440px;
  left: 50%;
  transform: translateX(-50%);

  .newsTitle {
    font-size: 38px;
    font-weight: 700;
    line-height: 150%;
    margin-bottom: 20px;
    font-size: 48px;
    font-family: var(--font-montserrat);
  }
  .newsSubTitle {
    font-size: 28px;
    font-weight: 500;
    line-height: 150%;
    color: var(--gray-700);
    margin-bottom: 20px;
  }
  .newsDate {
    font-size: 20px;
    font-weight: 500;
    line-height: 150%;
    color: var(--gray-700);
    padding-bottom: 60px;
    border-bottom: 1px solid var(--gray-700);
  }
  .newsText {
    font-size: 20px;
    font-weight: 400;
    line-height: 150%;
    margin-top: 60px;
    margin-bottom: 60px;
    img {
      width: 100%;
    }
  }
  .newsList {
    padding-top: 60px;
    border-top: 1px solid var(--gray-700);

    a {
      color: var(--gray-900);
    }
  }
}

.tab {
  position: absolute;
}
.tabTitle,
.tabTitle2 {
  position: absolute;
  top: 346px;
  left: 240px;
  width: 1920px;
  display: flex;
  display: flex;
  width: 720px;
  padding: 20px 0px;
  justify-content: center;
  align-items: center;
  gap: 5px;
  background: none;
  border: none;
  font-weight: 600;
  color: var(--gray-600, #939393);
  border-bottom: 1px solid var(--gray-400, #d0d0d0);
}
.tabTitle2 {
  left: 960px;
}
.tabActive {
  border: 1px solid var(--gray-900, #111);
  border-bottom: none;
  color: var(--gray-900, #111);
}

.membersProfileList {
  display: grid;
  position: absolute;
  top: 470px;
  left: 240px;
  gap: 20px;
  grid-template-columns: repeat(4, 1fr);
  width: 1444px;
  a {
    text-decoration: none;
    color: var(--color-white);
  }
}
.membersProfileWrapper {
  display: grid;
}
.membersProfileWrapper:hover {
  cursor: pointer;
  box-shadow: 8px 8px 20px rgba(17, 17, 17, 0.08);
  transform: scale(1.01);
}
.membersProfileImage {
  width: 345px;
  height: 236px;
  object-fit: cover;
  -webkit-user-drag: none;
  user-select: none;
}
.membersProfileNameWrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: var(--gap-5xs);
  /* margin-top: -52px; */
  padding: 24px;
  color: var(--color-darkslategray);
}
.membersProfileNameWrapper .membersProfileTitle {
  color: var(--gray-900, #111);
  font-family: Pretendard;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
}
.membersProfileNameWrapper .membersProfileSubTitle {
  color: var(--gray-700, #767676);
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 175%;
  margin-top: 4px;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* 텍스트를 3줄로 제한 */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis; /* 텍스트가 오버플로 될 때 "..."으로 표시 */
}
.membersProfileFooter {
  display: flex;
}
.membersProfileNameWrapper .membersProfileFooter .membersProfileDate {
  margin-top: 20px;
  color: var(--gray-800, #494949);
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%; /* 14px */
}
.membersProfileNameWrapper .membersProfileFooter .membersProfileMore {
  position: absolute;
  margin-top: 18px;
  margin-left: 242px;
  color: var(--gray-800, #494949);
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%; /* 14px */
  text-decoration-line: underline;
}
.membersProfileNameWrapper .membersProfileFooter .membersProfilePlus {
  position: absolute;
  margin-top: 17px;
  margin-left: 284px;
  width: 16px;
  height: 16px;
}

/* .profileLeeKiSangIcon,
.profileKimJinYeongIcon,
.thumbJangEuGeneIcon,
.thumbJangHanAIcon {
  position: absolute;
  top: 837px;
  left: 970px;
  width: 345px;
  height: 390px;
  object-fit: cover;
}
.profileJeonEunJuIcon,
.profileJungMinHyangIcon,
.profileParkSaeHaIcon {
  left: 240px;
}
.profileJeonEunJuIcon,
.profileParkSaeHaIcon {
  left: 605px;
}
.profileParkSaeHaIcon {
  top: 836px;
  left: 1335px;
  height: 391px;
} */

.profileLeeKiSangIcon,
.profileKimJinYeongIcon,
.thumbJangEuGeneIcon,
.thumbJangHanAIcon {
  position: absolute;
  top: 426px;
  left: 240px;
  width: 345px;
  height: 390px;
  object-fit: cover;
}
.profileKimJinYeongIcon {
  left: 605px;
}

.thumbJangEuGeneIcon,
.thumbJangHanAIcon {
  left: 970px;
}
.thumbJangHanAIcon {
  left: 1335px;
}
.dimed,
.dimed1,
.dimed2,
.dimed3 {
  position: absolute;
  top: 606px;
  background: linear-gradient(180deg, rgba(17, 17, 17, 0), rgba(17, 17, 17, 0.7));
  width: 345px;
  height: 210px;
}
.dimed {
  left: 240px;
  box-shadow: 4px 4px 30px rgba(0, 0, 0, 0.16);
}
.dimed1,
.dimed2,
.dimed3 {
  left: 605px;
}
.dimed2,
.dimed3 {
  left: 970px;
}
.dimed3 {
  left: 1335px;
}
.div,
.leeKiSang {
  position: relative;
  line-height: 100%;
  font-weight: 600;
}
.leeKiSang {
  font-size: var(--font-size-base);
  font-weight: 500;
  font-family: var(--font-montserrat);
  color: var(--color-gray-100);
}
.name,
.name1,
.name2,
.name3 {
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: var(--gap-5xs);
}
.name {
  top: 764px;
  left: 268px;
  box-shadow: 4px 4px 30px rgba(0, 0, 0, 0.16);
  align-items: center;
}
.name1,
.name2,
.name3 {
  top: 768px;
  left: 633px;
  align-items: flex-start;
}
.name2,
.name3 {
  left: 998px;
}
.name3 {
  left: 1363px;
  align-items: center;
}
.profileJeonEunJuIcon,
.profileJungMinHyangIcon,
.profileKimSoRaIcon,
.profileParkSaeHaIcon {
  position: absolute;
  top: 837px;
  left: 970px;
  width: 345px;
  height: 390px;
  object-fit: cover;
}
.profileJeonEunJuIcon,
.profileJungMinHyangIcon,
.profileParkSaeHaIcon {
  left: 240px;
}
.profileJeonEunJuIcon,
.profileParkSaeHaIcon {
  left: 605px;
}
.profileParkSaeHaIcon {
  top: 836px;
  left: 1335px;
  height: 391px;
}
.dimed4,
.dimed5,
.dimed6,
.dimed7 {
  position: absolute;
  top: 1017px;
  left: 240px;
  background: linear-gradient(180deg, rgba(17, 17, 17, 0), rgba(17, 17, 17, 0.7));
  width: 345px;
  height: 210px;
}
.dimed5,
.dimed6,
.dimed7 {
  left: 605px;
}
.dimed6,
.dimed7 {
  left: 970px;
}
.dimed7 {
  left: 1335px;
}
.name4,
.name5,
.name6,
.name7 {
  position: absolute;
  top: 1179px;
  left: 268px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-5xs);
}
.name5,
.name6,
.name7 {
  left: 633px;
}
.name6,
.name7 {
  left: 998px;
}
.name7 {
  left: 1363px;
}
.profileKimYooJeongIcon,
.profileLeeJiHoonIcon,
.profileLeeKangJunIcon,
.profileLeeSangJinIcon {
  position: absolute;
  top: 1249px;
  left: 240px;
  width: 345px;
  height: 390px;
  object-fit: cover;
}
.profileKimYooJeongIcon,
.profileLeeKangJunIcon,
.profileLeeSangJinIcon {
  left: 607px;
}
.profileKimYooJeongIcon,
.profileLeeSangJinIcon {
  left: 970px;
  width: 346px;
}
.profileLeeSangJinIcon {
  left: 1335px;
  width: 345px;
}
.dimed10,
.dimed11,
.dimed8,
.dimed9 {
  position: absolute;
  top: 1429px;
  left: 240px;
  background: linear-gradient(180deg, rgba(17, 17, 17, 0), rgba(17, 17, 17, 0.7));
  width: 345px;
  height: 210px;
}
.dimed10,
.dimed11,
.dimed9 {
  left: 605px;
}
.dimed10,
.dimed11 {
  left: 970px;
}
.dimed11 {
  left: 1335px;
}
.name10,
.name11,
.name8,
.name9 {
  position: absolute;
  top: 1591px;
  left: 268px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-5xs);
}
.name10,
.name11,
.name9 {
  left: 633px;
}
.name10,
.name11 {
  left: 998px;
}
.name11 {
  left: 1363px;
}
.thumbLeeSangJin {
  position: absolute;
  top: 1248px;
  left: 1335px;
  width: 348px;
  height: 390px;
}
.kfbaIcon {
  position: relative;
  width: 70px;
  height: 34px;
}
.aboutUs {
  position: relative;
  line-height: 100%;
  text-transform: uppercase;
  font-weight: 600;
}
.menu,
.menu1 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 38px;

  a {
    cursor: pointer;
    text-decoration: none;
    color: var(--gray-900);
  }
}
.menu {
  align-items: center;
  gap: 63px;
}
.kfba {
  color: var(--color-darkslategray);
}
.copyright2013Container,
.div12 {
  position: relative;
  line-height: 100%;
}
.copyright2013Container {
  button {
    border: none;
    background-color: transparent;
    padding: 0;
    padding: var(--padding-7xs) var(--padding-xs);
  }
}
.div12 {
  text-decoration: underline;
  font-weight: 500;
}
.buttons,
.info {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.buttons {
  align-items: flex-start;
  gap: var(--gap-xs);
  color: var(--gray-900);
  font-family: var(--font-pretendard);
}
.info {
  align-items: flex-end;
  gap: 19px;
  color: var(--gray-700);
}
.familyOfficeSolutions,
.footer {
  position: absolute;
  font-size: var(--font-size-base);
}
.footer {
  bottom: 0;
  left: 0;
  box-sizing: border-box;
  width: 1920px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 24px var(--padding-221xl);
  color: var(--color-gray-300);
  font-family: var(--font-montserrat);
  font-size: var(--font-size-sm);
  border-top: 1px solid #ddd;
}
.familyOfficeSolutions {
  top: 5973px;
  left: 383px;
  line-height: 30px;
  font-weight: 500;
  font-family: var(--font-inter);
  color: #868686;
  display: none;
}
.div14 {
  position: relative;
  line-height: 100%;
  align-items: center;
  width: 130px;
  height: 20px;
  line-height: 20px;
  flex-shrink: 0;
  display: inline-flex;
  /* color: var(--gray-900);

  &:hover {
    color: var(--color-white);
  } */
}
.bg,
.vector66Stroke {
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
}
.vector66Stroke {
  width: 20px;
  height: 20px;
}
.icoChevronUp20px {
  position: relative;
  width: 20px;
  height: 20px;
  display: inline-flex;
  vertical-align: text-bottom;
  margin-left: 6px;
}
.dropdown {
  position: absolute;
  top: 362px;
  left: 1479px;
  /* border: 1px solid var(--color-gray-300);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-5xs); */
  font-size: var(--font-size-base);
}
.dropdownHover {
  color: var(--gray-900);
  border: 1px solid var(--gray-900);
  padding: var(--padding-xs) 22px;
  position: relative;
  overflow: hidden;
  background-color: transparent;
  cursor: pointer;
  transition: color 1s ease;
  width: 203px;
  transition: color 1s ease;

  /* 배경색 애니메이션을 위한 의사 요소 */
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: -100%; /* 초기 위치 */
    width: 100%;
    height: 100%;
    background-color: var(--gray-900); /* 배경색 */
    transition: left 1s ease; /* 애니메이션 속성 */
  }

  /* 마우스 호버 시 */
  &:hover {
    border: 1px solid var(--gray-900);
    color: var(--color-white);
    &::before {
      left: 0; /* 왼쪽에서 오른쪽으로 애니메이션 */
    }
  }
}
.rectangle {
  position: relative;
  border: 1px solid var(--color-white);
  box-sizing: border-box;
  width: 16px;
  height: 16px;
}
.checkboxItem,
.mc {
  display: flex;
  align-items: center;
}
.mc {
  flex: 1;
  position: relative;
  line-height: 100%;
  height: 16px;
}
.checkboxItem {
  align-self: stretch;
  flex-direction: row;
  justify-content: flex-start;
  gap: var(--gap-5xs);
}
.checkbox {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border: 1px solid var(--color-white);
  box-sizing: border-box;
}
.checkboxCheckedChild {
  position: absolute;
  height: 47.24%;
  width: 68.9%;
  top: 29.04%;
  right: 14.54%;
  bottom: 23.72%;
  left: 16.56%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.checkboxChecked {
  position: relative;
  background-color: var(--color-white);
  width: 16px;
  height: 16px;
}
.checkboxItemList {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: var(--gap-xs);
}
.container,
.container1 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-end;
}
.container1 {
  align-self: stretch;
  width: 146px;
}
.container {
  gap: var(--gap-base);
}
.div25 {
  border: 1px solid var(--color-white);
  align-items: flex-end;
  justify-content: center;
  /* padding: var(--padding-7xs) var(--padding-xs); */
  color: var(--color-white);
  cursor: pointer;
}
.buttons1,
.div25,
.div27 {
  display: flex;
  flex-direction: row;
}
.div27 {
  background-color: var(--color-white);
  align-items: flex-end;
  justify-content: center;
  /* padding: var(--padding-7xs) var(--padding-xs); */
  color: var(--gray-900);
  cursor: pointer;
}
.buttons1 {
  align-items: center;
  justify-content: flex-end;
  gap: var(--gap-5xs);
  text-align: center;
  font-size: var(--font-size-smi);
}
.searchBar {
  position: absolute;
  top: 415px;
  left: 1340px;
  background-color: var(--gray-900);
  box-shadow: 4px 4px 16px rgba(17, 17, 17, 0.2);
  border: 1px solid var(--gray-900);
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  padding: var(--padding-base) var(--padding-base) var(--padding-xs);
  gap: var(--gap-base);
  font-size: var(--font-size-base);
  z-index: 99;
}
.logoBlackIcon {
  position: relative;
  width: 102px;
  height: 50px;
}
.home {
  position: relative;
  text-transform: uppercase;
}
.gnbBlack,
.menu2 {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.menu2 {
  justify-content: flex-start;
  gap: 56px;
}
.gnbBlack {
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(--color-white);
  width: 1920px;
  justify-content: space-between;
  padding: var(--padding-base) var(--padding-221xl);
  box-sizing: border-box;
  font-size: var(--font-size-base);
  color: var(--color-gray-300);
  font-family: var(--font-montserrat);
}
.profile {
  margin: 0;
}
.membersProfile {
  position: relative;
  line-height: 125%;
  text-transform: uppercase;
  color: var(--gray-900);
}
.span,
.span1 {
  font-weight: 300;
  font-family: var(--font-pretendard);
  color: var(--color-darkslategray);
}
.span1 {
  font-weight: 500;
  color: var(--gray-900);
}
.kfba2 {
  font-weight: 300;
}
.kfbaContainer {
  position: relative;
  font-size: 24px;
  line-height: 150%;
  display: inline-block;
  width: 710px;
  flex-shrink: 0;
  color: var(--color-darkslategray);
  font-family: var(--font-pretendard);
}
.title {
  position: absolute;
  top: 162px;
  left: 0;
  width: 1920px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  padding: 0 var(--padding-221xl);
  box-sizing: border-box;
  font-size: 48px;
  color: var(--color-gray-300);
  font-family: var(--font-montserrat);
}
.div29 {
  flex: 1;
  position: relative;
  line-height: 100%;
  display: flex;
  align-items: center;
  height: 20px;

  input {
    font-size: 16px !important;
    border: none;
    padding: 0.25rem 0 !important;
  }
  input:focus {
    outline: none;
    border-color: none;
    box-shadow: none;
  }
}
.searchBar1,
.span4 {
  color: var(--gray-900);
}
.searchBar1 {
  position: absolute;
  top: 362px;
  left: 1198px;
  border-bottom: 1px solid var(--color-gray-300);
  box-sizing: border-box;
  width: 265px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-xs) var(--padding-base);
  gap: var(--gap-5xs);
  font-size: var(--font-size-base);

  button {
    border: none;
    background-color: transparent;
    padding: 0;
  }
}
.span4 {
  font-weight: 600;
}
.div30 {
  position: absolute;
  top: 382px;
  left: 240px;
  line-height: 100%;
  color: var(--color-dimgray);
}
.pcMembers {
  position: relative;
  background-color: var(--color-white);
  width: 100%;
  /* height: 1821px; */
  height: auto;
  margin: 0 auto;
  left: 50%;
  transform: translateX(-50%);
  font-size: var(--font-size-xl);
  color: var(--color-white);
  font-family: var(--font-pretendard);
}
.pcMembersContainer {
  width: 1920px;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  /* display: contents; */
}
.footerBg {
  position: absolute;
  /* top: 846px; */
  bottom: 82px;
  width: 100%;
  height: 1px;
  background-color: #ddd;
}
@media screen and (min-width: 1480px) and (max-width: 1920px) {
  .pcMembers {
    width: 1920px;
    margin: 0 auto;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
  }
  .pcMembersContainer {
    width: 100%;
    position: relative;
    overflow: hidden;
  }
}
.footerBg {
  position: relative;
  width: 100%;
  height: 1px;
  background-color: #ddd;
}
@media screen and (max-width: 1480px) {
  .pcMembers {
    width: 1920px;
    margin: 0 auto;
    /* margin-left: -220px; */
    position: relative;
    clip-path: inset(0 calc(220px) 0 calc(220px));
  }
  .pcMembersContainer {
    width: 1480px;
    margin: 0 auto;
    position: relative;
    overflow: hidden;
  }
  .footer {
    border-top: 1px solid #ddd;
  }
  .section01Bg,
  .section02Bg,
  .section03Bg,
  .footerBg {
    display: none;
  }
}

/* header */
.pcHeaderContainer {
  width: 100%;
  margin: 0 auto;
  position: fixed;
  overflow: hidden;
  top: 0;
  z-index: 10;
  height: 82px;
  color: var(--gray-900);
  transition: background-color 1s;
}
.pcHeader {
  font-size: var(--font-size-sm);
  font-family: var(--font-pretendard);
  width: 1920px;
  height: 82px;
  margin: 0 auto;
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;
}
.pcHeaderScrolled {
  background-color: white;
  color: var(--gray-900);
}
.gnbWhite {
  position: fixed;
  top: 0;
  left: 0;
  width: 1920px;
  justify-content: space-between;
  padding: var(--padding-base) var(--padding-221xl);
  box-sizing: border-box;
  font-size: var(--font-size-base);
  font-family: var(--font-montserrat);
  z-index: 100;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.logoWhiteIcon {
  position: relative;
  width: 102px;
  height: 50px;
}
.menu {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 56px;
  a {
    color: inherit;
    text-decoration: none;
  }
}
.home {
  position: relative;
  text-transform: uppercase;
}

.parent {
  position: absolute;
  width: 100%;
  text-align: center;
  bottom: -64px;

  button {
    color: var(--gray-600, #939393);
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    background: none;
    border: none;
    border-bottom: 1px solid var(--gray-400, #d0d0d0);
    margin-left: 4px;
    margin-right: 4px;
    padding: 6px 13px;
  }
  .active {
    color: #000;
    font-weight: 700;
    border: 1px solid var(--gray-900, #111);
  }
}

/* floatingButton */
.button,
.buttonKakaotalk {
  position: relative;
  width: 64px;
  height: 64px;
}
.buttonKakaotalk {
  width: 72px;
  height: 72px;
}
.floatingButton {
  position: fixed;
  bottom: 40px;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xs);
  z-index: 100;
}
.bg4 {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 50%;
  background-color: var(--gray-900);
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.1);
  width: 64px;
  height: 64px;
}
.b1 {
  position: absolute;
  top: 25px;
  left: 7px;
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
  text-transform: uppercase;
}
.btnStyle {
  background: none;
  border: none;
  padding: 0;
}

@media screen and (min-width: 1480px) and (max-width: 1920px) {
  .pcHeader {
    width: 1920px;
    height: 100px;
    margin: 0 auto;
    position: fixed;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }
}
@media screen and (max-width: 1480px) {
  .pcHeader {
    width: 100%;
    margin: 0 auto;
  }
  .pcHeaderContainer {
    width: 100%;
  }

  .gnbWhite {
    width: 100%;
    padding: var(--padding-base) 20px;
    z-index: 10;
  }
}
