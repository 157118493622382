/* floatingButton start */
.button,
.buttonKakaotalk {
  position: relative;
  width: 64px;
  height: 64px;
}
.buttonKakaotalk {
  width: 72px;
  height: 72px;
}
.floatingButton {
  position: fixed;
  bottom: 40px;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xs);
}
.bg4 {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 50%;
  background-color: var(--gray-900);
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.1);
  width: 64px;
  height: 64px;
}
.b1 {
  position: absolute;
  top: 25px;
  left: 7px;
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
  text-transform: uppercase;
}
.btnStyle {
  background: none;
  border: none;
  padding: 0;
}
/* floatingButton end */
/* header start */
.header {
  position: fixed;
  display: flex;
  width: 100%;
  padding: 24px 20px 8px 20px;
  justify-content: space-between;
  align-items: center;
  z-index: 99;
  .menuIcon {
    background: none;
    border: none;
    padding: 0;
    img {
      width: 24px;
      height: 24px;
    }
  }
}
.headerScrolled {
  background-color: white;
  color: var(--gray-900);
}
.headerBg {
  visibility: hidden;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  opacity: 0;
  transition: opacity 0.5s ease;
  padding: 24px 20px 8px 20px;
  .menuIcon {
    background: none;
    border: none;
    padding: 0;
    float: right;
    line-height: 36px;
    img {
      width: 24px;
      height: 24px;
    }
  }
  .menu {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 56px;
    align-items: center;
    margin-top: 114px;
    a {
      color: var(--gray-900, #111);
      font-family: Montserrat;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      text-transform: uppercase;
      text-decoration: none;
      cursor: pointer;
    }
  }
}
.headerBg.active {
  visibility: visible;
  opacity: 1;
  z-index: 100;
}
/* header end */
/* footer start */
.footer {
  display: flex;
  width: 100%;
  padding: 40px 20px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 32px;
  .logo {
    width: 70px;
    height: 34px;
  }
  .menu {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    a {
      color: var(--gray-900, #111);
      font-family: Montserrat;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 100%; /* 14px */
      text-transform: uppercase;
      text-decoration: none;
    }
  }
}
.copy {
  display: flex;
  width: 100%;
  padding: 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  .copyText {
    color: var(--gray-900, #111);
    font-family: Pretendard;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
    text-decoration-line: underline;
    display: flex;
    gap: 11px;
  }
  .copyright {
    color: #939393;
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    b {
      font-weight: 700;
      color: #111;
    }
  }
}
/* footer end */

.bgContainer {
  position: relative;
}
.bg {
  position: absolute;
  top: 0;
  left: 0;
  height: 218vw;
  width: 100vw;
  transition: background-image 1s ease-in-out;
  background-size: 100% 100%; /* 이미지가 요소의 전체 크기를 채우도록 설정 */
  background-repeat: no-repeat; /* 이미지 반복 방지 */
  background-size: cover;
  background-position: center;
}
.bg.hidden {
  opacity: 0;
}
.mainTitle {
  padding-top: 126px;
  padding-bottom: 30px;
  text-align: center;
  position: relative;

  .div1 {
    color: var(--gray-100, #fafafa);
    font-family: Pretendard;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 125%;
    text-transform: capitalize;
    margin-bottom: 10px;
  }

  .div2 {
    color: var(--gray-100, #fafafa);
    text-align: center;
    font-family: Pretendard;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 150%;
  }

  button {
    margin-top: 28px;
    display: inline-flex;
    padding: 12px 20px;
    justify-content: center;
    align-items: flex-start;
    gap: 4px;
    border: 1px solid #fff;
    background: none;
    z-index: 1;
  }
  .button1 {
    color: #fff;
    font-family: Pretendard;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    display: flex;
  }
  .button2 {
    margin-right: 4px;
  }
  .button3 {
    img {
      width: 16px;
      height: 16px;
      vertical-align: sub;
    }
  }
}
.bgContainer2 {
  position: relative;
  height: 128%;
}
.bg2 {
  /* position: absolute;
  left: 0; */
  width: 100%;
  transition: opacity 1s ease;
}
.bg2.hidden {
  opacity: 0;
}
.animation {
  padding-top: 218vw;
  .div1 {
    color: var(--gray-100, #fafafa);
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    position: absolute;
    margin-top: -38px;
    margin-left: 20px;
  }
  .div2 {
    color: var(--gray-100, #fafafa);
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%;
  }
  .bgBg {
    position: absolute;
    margin-top: -33px;
    margin-left: 76px;
    width: 85px;
    height: 3px;
  }
  .bg1 {
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 3px;
    background-color: var(--color-white);
  }
  .bg2 {
    position: absolute;
    top: 0;
    left: 0;
    width: 2px;
    height: 8px;
    background-color: var(--color-white);
  }
  .bg3 {
    position: absolute;
    top: 0;
    left: 4px;
    background-color: var(--color-white);
    width: 2px;
    height: 8px;
  }
  .startBtn {
    position: absolute;
    width: 6px;
    height: 8px;
    left: 0;
    top: 0;
  }
  .btnWrap {
    position: absolute;
    margin-top: -35px;
    left: 168px;
    line-height: 8px;
    padding: 6px;
  }
}
.memberWrapper {
  padding: 60px 20px;
  background: var(--gray-100, #fafafa);
  .title {
    color: #111;
    font-family: Pretendard;
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: 125%;
  }
  .subTitle {
    color: var(--gray-800, #494949);
    font-family: Pretendard;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 150%;
    margin-top: 4px;
  }
  .buttonPlus {
    width: 44px;
    height: 44px;
    position: absolute;
    right: 20px;
  }
  .buttonPlusMore {
    position: absolute;
    right: 26px;
    color: var(--gray-900, #111);
    text-align: center;
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    margin-top: 44px;
  }
  .memberList {
    margin-top: 36px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 8px;
    a {
      text-decoration: none;
    }
    .memberBox {
      background: white;
      width: 100%;
      position: relative;
      aspect-ratio: 3 / 4;
      .memberImage {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: top;
      }
      .memberTitle {
        padding: 16px 12px 8px 12px;
        color: var(--gray-500, #b8b8b8);
        font-family: Pretendard;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%;
        a {
          color: var(--gray-500, #b8b8b8);
          text-decoration: none;
        }
      }
      .memberName {
        color: #3c3c3c;
        font-family: Pretendard;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 150%;
        padding: 0 12px;
      }
      .memberEnName {
        color: var(--gray-700, #767676);
        font-family: Montserrat;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        padding: 0 12px 16px 12px;
      }
    }
  }
}
.imgProfiles {
  position: absolute;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  height: 800px;
  overflow: hidden;
  img {
    width: 100%;
  }
}
.profileTitleBg {
  position: absolute;
  width: 100%;
  height: 800px;
  opacity: 0.7;
  background: var(--gray-900, #111);
  overflow: hidden;
}
.profileTitle {
  width: 100%;
  height: 800px;
  color: #fff;
  text-align: center;
  font-family: Pretendard;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  text-transform: uppercase;
  position: relative;
  padding-top: 310px;
}
.contactWrapper {
  padding: 32px 20px 20px 20px;
  .title {
    color: var(--gray-900, #111);
    font-family: Montserrat;
    font-size: 64px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    text-transform: uppercase;
  }
  .subTitle {
    color: #111;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 150%;
    position: absolute;
    margin-left: 105px;
    margin-top: 72px;
  }
}
.contactGif {
  width: 132px;
  float: right;
  margin-right: 20px;
}
.contactForm {
  margin-top: 24px;

  .placeholder {
    border-top: 1px solid var(--gray-900, #111);
    padding: 0 8px;
    input {
      border: none;
      margin: 0;
    }
    input:focus {
      outline: none;
      border-color: none;
      box-shadow: none;
    }

    textarea {
      border: none;
      margin: 0;
    }
    textarea:focus {
      outline: none;
      border-color: none;
      box-shadow: none;
    }
  }
  .checkInfo {
    display: flex;
    padding: 16px 20px 34px 16px;
    border-top: 1px solid var(--gray-900, #111);
    .checkText {
      line-height: 14px;
    }
    .under {
      text-decoration: underline;
      color: #111;
      font-family: Pretendard;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 100%; /* 14px */
      text-decoration-line: underline;
    }
    .underText {
      color: #747373;
      font-family: Pretendard;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 100%;
    }
  }
  .checkboxDefault {
    position: relative;
    margin-right: 12px;
  }
  .formBtn {
    cursor: pointer;
    margin: 0 20px;
    margin-bottom: 40px;
    background: var(--gray-900, #111);
    display: flex;
    padding: 16px 40px;
    justify-content: center;
    align-items: center;
    gap: 16px;
    div {
      color: #fff;
      font-family: Pretendard;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 100%;
    }
    img {
      width: 20px;
      height: 20px;
    }
  }
  .formBtnFooter {
    border-bottom: 1px solid var(--gray-900, #111);
  }
}
