/* @import url("https://fonts.googleapis.com/css2?family=Pretendard:wght@300;500;600&display=swap"); */
@import url('https://cdn.jsdelivr.net/gh/orioncactus/pretendard/dist/web/static/pretendard-dynamic-subset.css');
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@500&display=swap");

body {
  margin: 0;
  line-height: normal;
}
:root {
  /* fonts */
  --font-pretendard: Pretendard;
  --font-montserrat: Montserrat;
  --font-inter: Inter;

  /* font sizes */
  --font-size-xs: 12px;
  --font-size-smi: 13px;
  --font-size-sm: 14px;
  --font-size-base: 16px;
  --font-size-lg: 18px;
  --font-size-xl: 20px;
  --font-size-5xl: 24px;
  --font-size-33xl: 52px;
  --font-size-121xl: 140px;

  /* Colors */
  --color-white: #fff;
  --color-gainsboro-100: #d9d9d9;
  --color-gray-100: #fafafa;
  --color-gray-200: #939393;
  --color-gray-300: #A2A7AB;
  --color-gray-400: #111;
  --gray-700: #767676;
  --gray-900: #111;
  --color-darkslategray: #494949;
  --color-silver-100: #b8b8b8;
  --color-tomato: #ff3838;
  --color-darkgray: #979797;
  --color-dimgray: #6f6f6f;

  /* Gaps */
  --gap-9xs: 4px;
  --gap-5xs: 8px;
  --gap-xs: 12px;
  --gap-base: 16px;

  /* Paddings */
  --padding-7xs: 6px;
  --padding-xs: 12px;
  --padding-base: 16px;
  --padding-5xl: 24px;
  --padding-9xl: 28px;
  --padding-11xl: 30px;
  --padding-17xl: 36px;
  --padding-221xl: 240px;
  
}

.form-check-input {
  margin: 0;
  box-shadow: none;
  outline: none;
}
.form-check-input:checked {
  background-color: black !important;
  border-color: black !important;
  border: black !important;
  box-shadow: none;
  outline: none;
}
.form-check-input:focus {box-shadow: none;} 

.searchBar .form-check-input[type=checkbox] {
  border-radius: 0;
  background-color: transparent;
  border: 2px solid white;
}
.searchBar .form-check-input:checked {
  background-color: white !important;
  border-color: white !important;
  border: white !important;
  color: black !important;
  box-shadow: none;
  outline: none;
  /* --bs-form-check-bg-image: url('../public/profileList/icosearch20px.svg') !important; */
}
.searchBar .form-check-input:checked[type=checkbox] {
  --bs-form-check-bg-image: url('../public/profileList/checked.png') !important;
  background-size: 100% 100%; /* 이미지가 요소의 전체 크기를 채우도록 설정 */
  background-repeat: no-repeat; /* 이미지 반복 방지 */
}


/* swiper */
.swiper {
  width: 100%;
  height: 100%;
}
.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}
.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.webDiv {
  display: block;
}
.mobileDiv {
  display: none;
}
@media (max-width: 700px) {
  .webDiv {
    display: none;
  }
  .mobileDiv {
    display: block;
  }
}