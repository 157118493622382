.icoChevronRight16px {
  height: 20px;
  z-index: 11;
}
.icoChevronRight16pxItem {
  vertical-align: text-top;
}
.div1 {
  position: relative;
  font-weight: 600;
  z-index: 11;
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  text-decoration: none;
}
.profileBtn,
.parentBtn {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  line-height: 20px;
}
.parentBtn {
  gap: var(--gap-xs);
}
.profileBtn {
  position: absolute;
  top: 0;
  right: 0;
  border: 1px solid var(--gray-900);
  padding: var(--padding-base) var(--padding-17xl);
  text-align: center;
  color: var(--gray-900);
  position: relative;
  overflow: hidden;
  background-color: transparent;
  cursor: pointer;
  transition: color 1s ease;

  /* 배경색 애니메이션을 위한 의사 요소 */
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: -100%; /* 초기 위치 */
    width: 100%;
    height: 100%;
    background-color: var(--gray-900); /* 배경색 */
    transition: left 1s ease;
    z-index: 10;
  }

  /* 마우스 호버 시 */
  &:hover {
    border: 1px solid var(--gray-900);
    color: var(--color-white);
    &::before {
      left: 0; /* 왼쪽에서 오른쪽으로 애니메이션 */
    }
  }
}

.videoArrowLeft {
  background: none;
  border: none;
  margin-top: -209px;
  position: absolute;
  z-index: 999;
  padding: 0;
  display: none;
  img {
    -webkit-user-drag: none; /* Safari, Chrome */
    -khtml-user-drag: none; /* Konqueror */
    -moz-user-drag: none; /* Old Firefox */
    -o-user-drag: none; /* Opera */
    user-drag: none; /* CSS3 Spec */
    pointer-events: none; /* Prevent click events */
  }
}
.videoArrowRight {
  background: none;
  border: none;
  margin-top: -209px;
  margin-left: 520px;
  position: absolute;
  z-index: 999;
  padding: 0;
  display: none;
  img {
    -webkit-user-drag: none; /* Safari, Chrome */
    -khtml-user-drag: none; /* Konqueror */
    -moz-user-drag: none; /* Old Firefox */
    -o-user-drag: none; /* Opera */
    user-drag: none; /* CSS3 Spec */
    pointer-events: none; /* Prevent click events */
  }
}

.imageArrowLeft {
  background: none;
  border: none;
  margin-top: -440px;
  position: absolute;
  z-index: 999;
  padding: 0;
  display: none;
  img {
    -webkit-user-drag: none; /* Safari, Chrome */
    -khtml-user-drag: none; /* Konqueror */
    -moz-user-drag: none; /* Old Firefox */
    -o-user-drag: none; /* Opera */
    user-drag: none; /* CSS3 Spec */
    pointer-events: none; /* Prevent click events */
  }
}
.imageArrowRight {
  background: none;
  border: none;
  margin-top: -440px;
  margin-left: 520px;
  position: absolute;
  z-index: 999;
  padding: 0;
  display: none;
  img {
    -webkit-user-drag: none; /* Safari, Chrome */
    -khtml-user-drag: none; /* Konqueror */
    -moz-user-drag: none; /* Old Firefox */
    -o-user-drag: none; /* Opera */
    user-drag: none; /* CSS3 Spec */
    pointer-events: none; /* Prevent click events */
  }
}
.profileKimjinyoungIcon {
  position: absolute;
  top: 191px;
  left: 298px;
  width: 598px;
  height: 797px;
  object-fit: cover;
}
.profileKimjinyoungIcon:hover {
  .imageArrowLeft {
    display: block !important;
  }
  .imageArrowRight {
    display: block !important;
  }
}
.members {
  position: relative;
  line-height: 100%;
  font-weight: 500;
}
.membersText {
  color: var(--gray-600, #939393);
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%; /* 12px */
}

.members {
  position: absolute;
  width: 200px;
  line-height: 100%;
  font-weight: 500;
  border: 1px solid var(--color-white);
  padding: var(--padding-base) var(--padding-17xl);
  text-align: center;
  border: 1px solid var(--color-gray-400);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-base) var(--padding-5xl);
  gap: var(--gap-9xs);
  font-size: var(--font-size-base);
  color: var(--color-gray-400);
  color: red;
  line-height: 20px;
  z-index: 12;
  position: absolute;
  overflow: hidden;
  background-color: transparent;
  cursor: pointer;
  transition: color 1s ease;
  z-index: 999;
  /* 배경색 애니메이션을 위한 의사 요소 */
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: -100%; /* 초기 위치 */
    width: 100%;
    height: 100%;
    background-color: var(--gray-900); /* 배경색 */
    /* color: var(--color-white); */
    transition: left 1s ease;
    z-index: 10;
  }

  /* 마우스 호버 시 */
  &:hover {
    border: 1px solid var(--gray-900);
    color: var(--color-white) !important;
    &::before {
      left: 0;
    }
  }
}

.bg,
.vectorIcon1 {
  position: absolute;
  top: 0;
  left: 0;
  width: 12px;
  height: 12px;
}
.vectorIcon1 {
  top: 1.65px;
  left: 3.65px;
  width: 5.06px;
  height: 8.71px;
}
.iconArrowRight12px {
  position: relative;
  width: 12px;
  height: 12px;
}
.kimJinYoung {
  position: relative;
  line-height: 100%;
  font-weight: 500;
  color: var(--color-darkslategray);
}
.membersParent {
  top: 162px;
  left: 298px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 5px;
  font-size: var(--font-size-xs);
  color: var(--color-gray-200);
}
.arrowIcon,
.bg1,
.membersParent {
  position: absolute;
}
.bg1 {
  top: 0;
  left: 0;
  width: 24px;
  height: 24px;
  opacity: 0.6;
}
.arrowIcon {
  top: 1.65px;
  left: 6.29px;
  width: 11.06px;
  height: 20.71px;
}
.iconProfileArrowLeft {
  position: relative;
  width: 24px;
  height: 24px;
  cursor: pointer;
}
.div {
  position: relative;
  line-height: 100%;
}
.slash {
  align-items: center;
  justify-content: center;
  z-index: 999;
}
.page,
.page2,
.slash {
  display: flex;
  flex-direction: row;
  z-index: 999;
}
.page2 {
  align-items: center;
  justify-content: center;
  color: var(--color-gray-200);
}
.page {
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-9xs);
}
.bg2 {
  position: absolute;
  top: 0;
  left: 0;
  width: 24.5px;
  height: 24.5px;
  opacity: 0.6;
}
.arrowIcon1 {
  position: absolute;
  top: 1.65px;
  left: 6.65px;
  width: 11.06px;
  height: 20.71px;
}
.iconProfileArrowRight {
  position: relative;
  width: 24.5px;
  height: 24.5px;
  cursor: pointer;
}
.pagination {
  position: absolute;
  top: 822px;
  left: 298px;
  width: 134px;
  background-color: rgba(17, 17, 17, 0.6);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 10px;
  gap: var(--gap-5xs);
  color: var(--color-gray-100);
  z-index: 999;
}
.div3 {
  position: relative;
  line-height: 125%;
  font-weight: 500;
}
.ollehTv {
  margin-bottom: 0;
}
.ollehTvTbn {
  margin: 0;
  padding-left: var(--padding-5xl);
}
.ollehTvContainer {
  position: relative;
  font-size: var(--font-inherit);
  line-height: 175%;
  font-weight: 300;
  font-family: var(--font-inherit);
  color: var(--color-darkslategray);
  text-align: left;
}
.frameParent,
.parent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 620px;
}
.parent {
  gap: var(--gap-base);
}
.frameParent {
  position: absolute;
  top: 303px;
  left: 970px;
  gap: 40px;
  text-align: center;
  font-size: var(--font-size-xl);
  font-family: var(--font-pretendard);
}
.kimJinYoung1 {
  position: relative;
  font-size: 24px;
  line-height: 100%;
  font-family: var(--font-montserrat);
  color: var(--color-gray-200);
}
.title1 {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  gap: var(--gap-5xs);
}
.arrowIcon2,
.bg3 {
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
}
.arrowIcon2 {
  top: 3.65px;
  left: 6.65px;
  width: 7.06px;
  height: 12.71px;
}
.arrow {
  position: relative;
  width: 20px;
  height: 20px;
}
.button {
  /* border: 1px solid var(--color-gray-400);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-base) var(--padding-5xl);
  gap: var(--gap-9xs);
  font-size: var(--font-size-base);
  color: var(--color-gray-400); */
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.lineName,
.title {
  position: absolute;
}
.title {
  top: 191px;
  left: 970px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 143px;
  font-size: 52px;
  color: #2b2b2b;
  font-family: var(--font-pretendard);
}
.lineName {
  top: 262.5px;
  left: 969.5px;
  border-top: 1px solid #e9e9e9;
  box-sizing: border-box;
  width: 620px;
  height: 1px;
}
.logoBlackIcon {
  position: relative;
  width: 102px;
  height: 50px;
}
.home {
  position: relative;
  text-transform: uppercase;
}
.gnbBlack,
.menu {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.menu {
  justify-content: flex-start;
  gap: 56px;
}
.gnbBlack {
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(--color-white);
  width: 1920px;
  justify-content: space-between;
  padding: var(--padding-base) var(--padding-221xl);
  box-sizing: border-box;
}
.kfbaIcon {
  position: relative;
  width: 70px;
  height: 34px;
}
.history {
  position: relative;
  line-height: 100%;
  text-transform: uppercase;
  font-weight: 600;
}
.menu1,
.menu2 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 38px;

  a {
    cursor: pointer;
    text-decoration: none;
    color: var(--color-gray-300);
  }
}
.menu1 {
  align-items: center;
  gap: 63px;
}
.kfba {
  color: var(--color-darkslategray);
}
.div8 {
  position: relative;
  text-decoration: underline;
  line-height: 100%;
  font-weight: 500;
}
.buttons {
  align-items: flex-start;
  justify-content: flex-start;
  gap: 12px;
  color: var(--color-gray-400);
  font-family: var(--font-pretendard);
}
.buttons,
.footer,
.info {
  display: flex;
  flex-direction: row;
}
.info {
  align-items: flex-end;
  justify-content: flex-start;
  gap: 19px;
  color: var(--color-gray-300);
}
.footer {
  position: absolute;
  bottom: 0;
  left: 0;
  box-sizing: border-box;
  width: 1920px;
  align-items: center;
  justify-content: space-between;
  padding: var(--padding-5xl) var(--padding-221xl);
  font-size: var(--font-size-sm);
  border-top: 1px solid #111;
}
.indicator1,
.indicator2 {
  position: relative;
  border-radius: 50%;
  background-color: var(--color-gray-400);
  width: 8px;
  height: 8px;
  cursor: pointer;
}
.indicator2 {
  background-color: var(--color-gainsboro-100);
}
.indicator {
  position: absolute;
  top: 1434px;
  left: 298px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: 600px;
}
.indicatorImage {
  position: absolute;
  top: 817px;
  left: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: 600px;
}
.videoIcon {
  position: absolute;
  top: 1076px;
  left: 298px;
  width: 600px;
  height: 338px;
}
.videoIcon:hover {
  .videoArrowLeft {
    display: block !important;
  }
  .videoArrowRight {
    display: block !important;
  }
}
.pcMembersDetail {
  position: relative;
  background-color: var(--color-white);
  width: 100%;
  height: 1476px;
  text-align: left;
  font-size: var(--font-size-base);
  color: var(--color-gray-400);
  font-family: var(--font-montserrat);
}
.pcMembersDetailContainer {
  width: 1920px;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  /* display: contents; */
}

/* floatingButton */
.button,
.buttonKakaotalk {
  position: relative;
  width: 64px;
  height: 64px;
}
.buttonKakaotalk {
  width: 72px;
  height: 72px;
}
.floatingButton {
  position: fixed;
  bottom: 40px;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xs);
}
.bg4 {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 50%;
  background-color: var(--gray-900);
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.1);
  width: 64px;
  height: 64px;
}
.b1 {
  position: absolute;
  top: 25px;
  left: 7px;
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
  text-transform: uppercase;
}
.btnStyle {
  background: none;
  border: none;
  padding: 0;
}

@media screen and (min-width: 1480px) and (max-width: 1920px) {
  .pcMembersDetail {
    width: 1920px;
    margin: 0 auto;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
  }
  .pcMembersDetailContainer {
    width: 100%;
    position: relative;
    overflow: hidden;
  }
}
@media screen and (max-width: 1480px) {
  .pcMembersDetail {
    width: 1920px;
    margin: 0 auto;
    left: 50%;
    transform: translateX(-50%);
    position: relative;
    clip-path: inset(0 calc(220px) 0 calc(220px));
  }
  .pcMembersDetailContainer {
    width: 1480px;
    margin: 0 auto;
    position: relative;
    overflow: hidden;
  }
  .footer {
    border-top: 1px solid #111;
  }
  .section04 {
    border-top: 1px solid #111;
  }
  .section01Bg,
  .section02Bg,
  .section03Bg,
  .footerBg,
  .contactBg {
    display: none;
  }
}

/* header */
.pcHeaderContainer {
  width: 100%;
  margin: 0 auto;
  position: fixed;
  overflow: hidden;
  top: 0;
  z-index: 10;
  height: 82px;
  color: var(--gray-900);
  transition: background-color 1s;
}
.pcHeader {
  font-size: var(--font-size-sm);
  font-family: var(--font-pretendard);
  width: 1920px;
  height: 82px;
  margin: 0 auto;
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;
}
.pcHeaderScrolled {
  background-color: white;
  color: var(--gray-900);
}
.gnbWhite {
  position: fixed;
  top: 0;
  left: 0;
  width: 1920px;
  justify-content: space-between;
  padding: var(--padding-base) var(--padding-221xl);
  box-sizing: border-box;
  font-size: var(--font-size-base);
  font-family: var(--font-montserrat);
  z-index: 100;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.logoWhiteIcon {
  position: relative;
  width: 102px;
  height: 50px;
}
.menu {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 56px;
  a {
    color: inherit;
    text-decoration: none;
  }
}
.home {
  position: relative;
  text-transform: uppercase;
}
@media screen and (min-width: 1480px) and (max-width: 1920px) {
  .pcHeader {
    width: 1920px;
    height: 100px;
    margin: 0 auto;
    position: fixed;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }
}
.contactBg {
  position: absolute;
  width: 100%;
  height: 1px;
  background-color: #111;
}
.footerBg {
  position: absolute;
  width: 100%;
  height: 1px;
  background-color: #111;
}
@media screen and (max-width: 1480px) {
  .pcHeader {
    width: 100%;
    margin: 0 auto;
  }
  .pcHeaderContainer {
    width: 100%;
  }

  .gnbWhite {
    width: 100%;
    padding: var(--padding-base) 20px;
    z-index: 10;
  }
}

.section04 {
  position: absolute;
  left: calc(50% - 720px);
  width: 1679px;
  height: 500px;
  font-size: var(--font-size-base);
  color: var(--color-darkgray);
  border-top: 1px solid #111;
}

.span1 {
  font-weight: 500;
  font-family: var(--font-montserrat);
}
.span2 {
  font-weight: 300;
}
.p,
.p1 {
  margin: 0;
}
.p1 {
  font-weight: 300;
}
.checkbox,
.div5 {
  position: absolute;
}
.div5 {
  top: 226px;
  left: calc(50% - 617.5px);
  font-size: var(--font-size-xl);
  line-height: 150%;
  color: var(--gray-900);
  display: inline-block;
  width: 400px;
}
.checkbox {
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border: 1px solid #c9c9c9;
  box-sizing: border-box;
}
.checkboxDefault {
  position: relative;
  /* width: 16px;
  height: 16px; */
}
.span3 {
  text-decoration: underline;
}
.span4 {
  color: #747373;
}
.div6 {
  position: relative;
  line-height: 100%;
  font-weight: 500;
}
.checkInfo {
  position: absolute;
  top: 445px;
  left: 1073px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-xs);
  color: var(--gray-900);
}
.span5 {
  font-family: var(--font-pretendard);
  color: var(--color-tomato);
}

.div7 {
  position: absolute;
  top: 134px;
  left: 860px;
  line-height: 100%;
  display: inline-block;
  width: 548px;
  font-family: var(--font-montserrat);

  input {
    border: none;
    width: 600px;
  }
  input:focus {
    outline: none;
    border-color: none;
    box-shadow: none;
  }
}
.line,
.line1,
.line2,
.line3,
.line4 {
  position: absolute;
  box-sizing: border-box;
}
.line {
  top: -0.5px;
  left: 851.5px;
  border-right: 1px solid var(--gray-900);
  width: 1px;
  height: 498px;
}
.line1,
.line2,
.line3,
.line4 {
  top: 63.5px;
  left: 852.5px;
  border-top: 1px solid var(--gray-900);
  width: 827px;
  height: 1px;
}
.line2,
.line3,
.line4 {
  top: 127.5px;
}
.line3,
.line4 {
  top: 192.5px;
}
.line4 {
  top: 406.5px;
}
.span6 {
  color: var(--color-tomato);
}
.div9,
.placeholder {
  position: absolute;
  left: 860px;
  width: 547px;
}
.mb-3 {
  margin-bottom: 0 !important;
  border: none;
}
.placeholder {
  top: 2px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  input {
    border: none;
    width: 600px;
  }
  input:focus {
    outline: none;
    border-color: none;
    box-shadow: none;
  }
}
.div9 {
  top: 195px;
  line-height: 175%;
  display: inline-block;
  height: 166px;

  textarea {
    border: none;
    width: 600px;
  }
  textarea:focus {
    outline: none;
    border-color: none;
    box-shadow: none;
  }
}
.contact,
.div10,
.us {
  position: absolute;
  line-height: 100%;
}
.div10 {
  top: 69px;
  left: 860px;
  display: inline-block;
  width: 547px;
  font-family: var(--font-montserrat);

  input {
    border: none;
    width: 600px;
  }
  input:focus {
    outline: none;
    border-color: none;
    box-shadow: none;
  }
}
.contact,
.us {
  top: 0;
  left: 0;
  text-transform: uppercase;
  p {
    margin: 0;
  }
}
.us {
  top: 140px;
}
.profile2Icon {
  position: absolute;
  top: 166px;
  left: 530px;
  width: 178px;
  height: 210px;
  object-fit: cover;
}
.contents {
  position: absolute;
  top: 60px;
  left: 0;
  width: 708px;
  height: 376px;
  font-size: var(--font-size-121xl);
  color: var(--gray-900);
  font-family: var(--font-montserrat);
}
.icoChevronRight16pxInner {
  position: absolute;
  top: 0;
  left: 0;
  width: 16px;
  height: 16px;
  z-index: 11;
}
.vectorIcon {
  top: 1.25px;
  left: 4.45px;
  width: 10px;
  height: 18px;
  z-index: 11;
}
.div11 {
  position: absolute;
  top: 429px;
  left: calc(50% + 33.5px);
  background-color: var(--gray-900);
  border: 1px solid var(--gray-900);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-base) var(--padding-17xl);
  text-align: center;
  font-size: var(--font-size-sm);
  color: var(--color-white);

  /* position: relative;
  overflow: hidden; */
  /* background-color: transparent; */
  overflow: hidden;
  cursor: pointer;
  transition: color 1s ease;

  /* 배경색 애니메이션을 위한 의사 요소 */
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: -100%; /* 초기 위치 */
    width: 100%;
    height: 100%;
    background-color: var(--color-white); /* 배경색 */
    transition: left 1s ease;
    /* z-index: 10; */
  }

  /* 마우스 호버 시 */
  &:hover {
    border: 1px solid var(--gray-900);
    color: var(--gray-900);
    &::before {
      left: 0; /* 왼쪽에서 오른쪽으로 애니메이션 */
    }
  }
}

.div,
.parent2 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.parent2 {
  gap: var(--gap-xs);
}
