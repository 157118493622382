/* floatingButton start */
.button,
.buttonKakaotalk {
  position: relative;
  width: 64px;
  height: 64px;
}
.buttonKakaotalk {
  width: 72px;
  height: 72px;
}
.floatingButton {
  position: fixed;
  bottom: 40px;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xs);
}
.bg4 {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 50%;
  background-color: var(--gray-900);
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.1);
  width: 64px;
  height: 64px;
}
.b1 {
  position: absolute;
  top: 25px;
  left: 7px;
  color: #FFF;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
  text-transform: uppercase;
}
.btnStyle {
  background: none;
  border: none;
  padding: 0;
}
/* floatingButton end */
/* header start */
.header {
  position: fixed;
  display: flex;
  width: 100%;
  padding: 24px 20px 8px 20px;
  justify-content: space-between;
  align-items: center;
  z-index: 99;
  .menuIcon {
    background: none;
    border: none;
    padding: 0;
    img {
      width: 24px;
      height: 24px;
    }
  }
}
.headerScrolled {
  background-color: white;
  color: var(--gray-900);
}
.headerBg {
  visibility: hidden;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  opacity: 0;
  transition: opacity 0.5s ease;
  padding: 24px 20px 8px 20px;
  .menuIcon {
    background: none;
    border: none;
    padding: 0;
    float: right;
    line-height: 36px;
    img {
      width: 24px;
      height: 24px;
    }
  }
  .menu {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 56px;
    align-items: center;
    margin-top: 114px;
    a {
      color: var(--gray-900, #111);
      font-family: Montserrat;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      text-transform: uppercase;
      text-decoration: none;
      cursor: pointer;
    }
  }
}
.headerBg.active {
  visibility: visible;
  opacity: 1;
  z-index: 100;
}
/* header end */
/* footer start */
.footer {
  display: flex;
  width: 100%;
  padding: 40px 20px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 32px;
  .logo {
    width: 70px;
    height: 34px;
  }
  .menu {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    a {
      color: var(--gray-900, #111);
      font-family: Montserrat;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 100%; /* 14px */
      text-transform: uppercase;
      text-decoration: none;
    }
  }
}
.copy {
  display: flex;
  width: 100%;
  padding: 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  .copyText {
    color: var(--gray-900, #111);
    font-family: Pretendard;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
    text-decoration-line: underline;
    display: flex;
    gap: 11px;
  }
  .copyright {
    color: #939393;
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    b {
      font-weight: 700;
      color: #111;
    }
  }
}
/* footer end */

.containerImg {
  padding: 0 20px;
  padding-top: 128px;

  div {
    color: var(--gray-900, #111);
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    text-transform: capitalize;
    transform: rotate(-90deg);
    position: absolute;
    left: -45px;
    top: 202px;
  }
  img {
    width: 80%;
    margin-left: 32px;
  }
}
.containerText {
  padding: 60px 20px;
  .hello {
    color: var(--gray-900, #111);
    font-family: Pretendard;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 125%; 
  }
  .text {
    color: var(--gray-900, #111);
    font-family: Pretendard;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 175%;
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .kfba {
    color: var(--gray-800, #494949);
    font-family: Pretendard;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
  }
  .name {
    color: var(--gray-900, #111);
    font-family: Pretendard;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
  }
}
