.div1 {
  position: relative;
  line-height: 100%;
  font-weight: 600;
  z-index: 11;
}
.icoChevronRight16pxChild {
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  z-index: 11;
}
.icoChevronRight16pxItem {
  position: absolute;
  left: 0;
  z-index: 11;
}
.icoChevronRight16px {
  position: relative;
  width: 20px;
  height: 20px;
}
.div,
.parent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.parent {
  gap: var(--gap-xs);
}
.div {
  position: absolute;
  top: 465px;
  left: calc(50% - 722px);
  border: 1px solid var(--color-white);
  padding: var(--padding-base) var(--padding-17xl);
  text-align: center;
  color: var(--color-white);

  position: relative;
  overflow: hidden;
  background-color: transparent;
  cursor: pointer;
  transition: color 1s ease;

  /* 배경색 애니메이션을 위한 의사 요소 */
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: -100%; /* 초기 위치 */
    width: 100%;
    height: 100%;
    background-color: var(--gray-900); /* 배경색 */
    transition: left 1s ease;
    z-index: 10;
  }

  /* 마우스 호버 시 */
  &:hover {
    border: 1px solid var(--gray-900);
    &::before {
      left: 0; /* 왼쪽에서 오른쪽으로 애니메이션 */
    }
  }
}
.span {
  font-weight: 600;
}
.bg,
.div2 {
  position: absolute;
}
.div2 {
  top: 0;
  left: 0;
  line-height: 100%;
}
.bg {
  top: 6px;
  left: 56px;
  width: 85px;
  height: 3px;
  opacity: 0.2;
}
.bgBg {
  position: absolute;
  top: 6px;
  left: 56px;
  width: 85px;
  height: 3px;
}
.startBtn {
  position: absolute;
  width: 6px;
  height: 8px;
  left: 0;
  top: 2px;
}
.btnWrap {
  position: absolute;
  top: 1px;
  left: 157px;
  line-height: 8px;
  padding: 6px;
}
.bg,
.bg1,
.bg2 {
  background-color: var(--color-white);
}
.bg1 {
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 3px;
}
.bg2 {
  top: 1px;
  left: 0;
  width: 2px;
  height: 8px;
}
.animation,
.bg2,
.bg3 {
  position: absolute;
}
.bg3 {
  top: 1px;
  left: 4px;
  background-color: var(--color-white);
  width: 2px;
  height: 8px;
}
.animation {
  top: 673px;
  left: 240px;
  width: 155px;
  height: 14px;
  font-family: var(--font-montserrat);
}
.div3,
.div4 {
  position: relative;
  p {
    margin: 0;
  }
}
.div3 {
  line-height: 125%;
  text-transform: capitalize;
}
.div4 {
  font-size: var(--font-size-5xl);
  line-height: 150%;
  font-weight: 300;
}
.title {
  position: absolute;
  top: 262px;
  left: 240px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-xs);
  font-size: var(--font-size-33xl);
}
.section01 {
  position: absolute;
  top: 0;
  left: 0;
  width: 1920px;
  height: 800px;
  background-color: transparent;
  color: var(--color-gray-100);
}
.span1 {
  font-weight: 500;
  font-family: var(--font-montserrat);
}
.span2 {
  font-weight: 300;
}
.p,
.p1 {
  margin: 0;
}
.p1 {
  font-weight: 300;
}
.checkbox,
.div5 {
  position: absolute;
}
.div5 {
  top: 226px;
  left: calc(50% - 617.5px);
  font-size: var(--font-size-xl);
  line-height: 150%;
  color: var(--gray-900);
  display: inline-block;
  width: 400px;
}
.checkbox {
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border: 1px solid #c9c9c9;
  box-sizing: border-box;
}
.checkboxDefault {
  position: relative;
  /* width: 16px;
  height: 16px; */
}
.span3 {
  text-decoration: underline;
}
.span4 {
  color: #747373;
}
.div6 {
  position: relative;
  line-height: 100%;
  font-weight: 500;
}
.checkInfo {
  position: absolute;
  top: 445px;
  left: 1073px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-xs);
  color: var(--gray-900);
}
.span5 {
  font-family: var(--font-pretendard);
  color: var(--color-tomato);
}
.div7 {
  position: absolute;
  top: 134px;
  left: 860px;
  line-height: 100%;
  display: inline-block;
  width: 548px;
  font-family: var(--font-montserrat);

  input {
    border: none;
    width: 600px;
  }
  input:focus {
    outline: none;
    border-color: none;
    box-shadow: none;
  }
}
.lineWrap {
  position: absolute;
  top: 2495px;
}
.line,
.line1,
.line2,
.line3,
.line4 {
  position: absolute;
  box-sizing: border-box;
}
.line {
  top: -0.5px;
  left: 851.5px;
  border-right: 1px solid var(--gray-900);
  width: 1px;
  height: 500px;
}
.line1,
.line2,
.line3,
.line4 {
  top: 63.5px;
  left: 852.5px;
  border-top: 1px solid var(--gray-900);
  width: 827px;
  height: 1px;
}
.line2,
.line3,
.line4 {
  top: 127.5px;
}
.line3,
.line4 {
  top: 192.5px;
}
.line4 {
  top: 406.5px;
}
.span6 {
  color: var(--color-tomato);
}
.div9,
.placeholder {
  position: absolute;
  left: 860px;
  width: 547px;
}
.mb-3 {
  margin-bottom: 0 !important;
  border: none;
}
.placeholder {
  top: 2px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  input {
    border: none;
    width: 600px;
  }
  input:focus {
    outline: none;
    border-color: none;
    box-shadow: none;
  }
}
.div9 {
  top: 195px;
  line-height: 175%;
  display: inline-block;
  height: 166px;

  textarea {
    border: none;
    width: 600px;
  }
  textarea:focus {
    outline: none;
    border-color: none;
    box-shadow: none;
  }
}
.contact,
.div10,
.us {
  position: absolute;
  line-height: 100%;
}
.div10 {
  top: 69px;
  left: 860px;
  display: inline-block;
  width: 547px;
  font-family: var(--font-montserrat);

  input {
    border: none;
    width: 600px;
  }
  input:focus {
    outline: none;
    border-color: none;
    box-shadow: none;
  }
}
.contact,
.us {
  top: 0;
  left: 0;
  text-transform: uppercase;
  p {
    margin: 0;
  }
}
.us {
  top: 140px;
}
.profile2Icon {
  position: absolute;
  top: 166px;
  left: 530px;
  width: 178px;
  height: 210px;
  object-fit: cover;
}
.contents {
  position: absolute;
  top: 60px;
  left: 0;
  width: 708px;
  height: 376px;
  font-size: var(--font-size-121xl);
  color: var(--gray-900);
  font-family: var(--font-montserrat);
}
.icoChevronRight16pxInner {
  position: absolute;
  top: 0;
  left: 0;
  width: 16px;
  height: 16px;
  z-index: 11;
}
.vectorIcon {
  top: 1.25px;
  left: 4.45px;
  width: 10px;
  height: 18px;
  z-index: 11;
}
.div11 {
  position: absolute;
  top: 429px;
  left: calc(50% + 33.5px);
  background-color: var(--gray-900);
  border: 1px solid var(--gray-900);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-base) var(--padding-17xl);
  text-align: center;
  font-size: var(--font-size-sm);
  color: var(--color-white);

  /* position: relative;
  overflow: hidden; */
  /* background-color: transparent; */
  overflow: hidden;
  cursor: pointer;
  transition: color 1s ease;

  /* 배경색 애니메이션을 위한 의사 요소 */
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: -100%; /* 초기 위치 */
    width: 100%;
    height: 100%;
    background-color: var(--color-white); /* 배경색 */
    transition: left 1s ease;
    /* z-index: 10; */
  }

  /* 마우스 호버 시 */
  &:hover {
    border: 1px solid var(--gray-900);
    color: var(--gray-900);
    &::before {
      left: 0; /* 왼쪽에서 오른쪽으로 애니메이션 */
    }
  }
}
.section04 {
  position: absolute;
  top: 2562px;
  left: calc(50% - 720px);
  width: 1679px;
  height: 500px;
  font-size: var(--font-size-base);
  color: var(--color-darkgray);
}
.thumbAhnSooMinIcon,
.thumbAnDaYunIcon,
.thumbBaekIlHongIcon,
.thumbBaekSuJinIcon {
  position: absolute;
  top: 0;
  left: 0;
  width: 96px;
  height: 112.57px;
  object-fit: cover;
}
.thumbAnDaYunIcon,
.thumbBaekIlHongIcon,
.thumbBaekSuJinIcon {
  left: 96px;
}
.thumbBaekIlHongIcon,
.thumbBaekSuJinIcon {
  left: 192px;
}
.thumbBaekSuJinIcon {
  left: 288px;
}
.thumbBaekSunHyeIcon,
.thumbBrittyNaKyungKoIcon,
.thumbChaSeungIlIcon,
.thumbChaYuJinIcon {
  position: absolute;
  top: 0;
  left: 384px;
  width: 96px;
  height: 112.57px;
  object-fit: cover;
}
.thumbBrittyNaKyungKoIcon,
.thumbChaSeungIlIcon,
.thumbChaYuJinIcon {
  left: 480px;
}
.thumbChaSeungIlIcon,
.thumbChaYuJinIcon {
  left: 576px;
}
.thumbChaYuJinIcon {
  left: 672px;
}
.thumbChangChaeRinIcon,
.thumbChoAmyIcon,
.thumbChoiInKyungIcon,
.thumbChoiSulKi2Icon {
  position: absolute;
  top: 0;
  left: 768px;
  width: 96px;
  height: 112.57px;
  object-fit: cover;
}
.thumbChoAmyIcon,
.thumbChoiInKyungIcon,
.thumbChoiSulKi2Icon {
  left: 864px;
}
.thumbChoiInKyungIcon,
.thumbChoiSulKi2Icon {
  left: 960px;
}
.thumbChoiSulKi2Icon {
  left: 1056px;
}
.thumbChoiSulKiIcon,
.thumbChoiYeJinIcon,
.thumbKwonYuRiIcon,
.thumbLeeBoRamIcon {
  position: absolute;
  top: 0;
  left: 1152px;
  width: 96px;
  height: 112.57px;
  object-fit: cover;
}
.thumbChoiYeJinIcon,
.thumbKwonYuRiIcon,
.thumbLeeBoRamIcon {
  left: 1248px;
}
.thumbKwonYuRiIcon,
.thumbLeeBoRamIcon {
  left: 1344px;
}
.thumbLeeBoRamIcon {
  left: 1440px;
}
.thumbLeeClaudiaIcon,
.thumbLeeDaInIcon,
.thumbLeeDoKyungIcon,
.thumbLeeHaRimIcon {
  position: absolute;
  top: 0;
  left: 1536px;
  width: 96px;
  height: 112.57px;
  object-fit: cover;
}
.thumbLeeDaInIcon,
.thumbLeeDoKyungIcon,
.thumbLeeHaRimIcon {
  left: 1632px;
}
.thumbLeeDoKyungIcon,
.thumbLeeHaRimIcon {
  left: 1728px;
}
.thumbLeeHaRimIcon {
  left: 1824px;
}
.thumbHanJiHeeIcon,
.thumbHanJuEunIcon,
.thumbHongKyeongHwaIcon,
.thumbHongTaeGyunIcon {
  position: absolute;
  top: 112.57px;
  left: 0;
  width: 96px;
  height: 112.57px;
  object-fit: cover;
}
.thumbHanJuEunIcon,
.thumbHongKyeongHwaIcon,
.thumbHongTaeGyunIcon {
  left: 96px;
}
.thumbHongKyeongHwaIcon,
.thumbHongTaeGyunIcon {
  left: 192px;
}
.thumbHongTaeGyunIcon {
  left: 288px;
}
.thumbHongYooIcon,
.thumbHwangBoRaIcon,
.thumbHwangInSungIcon,
.thumbHwangNuRiIcon {
  position: absolute;
  top: 112.57px;
  left: 384px;
  width: 96px;
  height: 112.57px;
  object-fit: cover;
}
.thumbHwangBoRaIcon,
.thumbHwangInSungIcon,
.thumbHwangNuRiIcon {
  left: 480px;
}
.thumbHwangInSungIcon,
.thumbHwangNuRiIcon {
  left: 576px;
}
.thumbHwangNuRiIcon {
  left: 672px;
}
.thumbHwangYeRinIcon,
.thumbImHyunJungIcon,
.thumbJangEuGeneIcon,
.thumbLeeJinJooIcon {
  position: absolute;
  top: 112.57px;
  left: 768px;
  width: 96px;
  height: 112.57px;
  object-fit: cover;
}
.thumbImHyunJungIcon,
.thumbJangEuGeneIcon,
.thumbLeeJinJooIcon {
  left: 864px;
}
.thumbJangEuGeneIcon,
.thumbLeeJinJooIcon {
  left: 960px;
}
.thumbLeeJinJooIcon {
  left: 1056px;
}
.thumbLeeJuneIcon,
.thumbLeeKaEunIcon,
.thumbLeeKangJunIcon,
.thumbLeeKiSangIcon {
  position: absolute;
  top: 112.57px;
  left: 1152px;
  width: 96px;
  height: 112.57px;
  object-fit: cover;
}
.thumbLeeKaEunIcon,
.thumbLeeKangJunIcon,
.thumbLeeKiSangIcon {
  left: 1248px;
}
.thumbLeeKangJunIcon,
.thumbLeeKiSangIcon {
  left: 1344px;
}
.thumbLeeKiSangIcon {
  left: 1440px;
}
.thumbLeeKyoungYounIcon,
.thumbLeeMinYoungIcon,
.thumbLeeNaRaeIcon,
.thumbLeeRoEunIcon {
  position: absolute;
  top: 112.57px;
  left: 1536px;
  width: 96px;
  height: 112.57px;
  object-fit: cover;
}
.thumbLeeMinYoungIcon,
.thumbLeeNaRaeIcon,
.thumbLeeRoEunIcon {
  left: 1632px;
}
.thumbLeeNaRaeIcon,
.thumbLeeRoEunIcon {
  left: 1728px;
}
.thumbLeeRoEunIcon {
  left: 1824px;
}
.thumbJeonSeungMinIcon,
.thumbJeongJaeKyungIcon,
.thumbJeongSoHaIcon,
.thumbJessicaJiYoungParkIcon {
  position: absolute;
  top: 225.14px;
  left: 0;
  width: 96px;
  height: 112.57px;
  object-fit: cover;
}
.thumbJeongJaeKyungIcon,
.thumbJeongSoHaIcon,
.thumbJessicaJiYoungParkIcon {
  left: 96px;
}
.thumbJeongSoHaIcon,
.thumbJessicaJiYoungParkIcon {
  left: 192px;
}
.thumbJessicaJiYoungParkIcon {
  left: 288px;
}
.thumbJoAhRamIcon,
.thumbJoHaNeulIcon,
.thumbJungAeRanIcon,
.thumbJungDaYoungIcon {
  position: absolute;
  top: 225.14px;
  left: 384px;
  width: 96px;
  height: 112.57px;
  object-fit: cover;
}
.thumbJoHaNeulIcon,
.thumbJungAeRanIcon,
.thumbJungDaYoungIcon {
  left: 480px;
}
.thumbJungAeRanIcon,
.thumbJungDaYoungIcon {
  left: 576px;
}
.thumbJungDaYoungIcon {
  left: 672px;
}
.thumbLeeSoYeonIcon,
.thumbLeeSooBinIcon,
.thumbLeeYongSeokIcon,
.thumbLeeYuGyeongIcon {
  position: absolute;
  top: 225.14px;
  left: 768px;
  width: 96px;
  height: 112.57px;
  object-fit: cover;
}
.thumbLeeSooBinIcon,
.thumbLeeYongSeokIcon,
.thumbLeeYuGyeongIcon {
  left: 864px;
}
.thumbLeeYongSeokIcon,
.thumbLeeYuGyeongIcon {
  left: 960px;
}
.thumbLeeYuGyeongIcon {
  left: 1056px;
}
.thumbLeeYunJiIcon,
.thumbMinKyoungSuIcon,
.thumbMisorangNatashaSeoIcon,
.thumbMoonJeongEunIcon {
  position: absolute;
  top: 225.14px;
  left: 1152px;
  width: 96px;
  height: 112.57px;
  object-fit: cover;
}
.thumbMinKyoungSuIcon,
.thumbMisorangNatashaSeoIcon,
.thumbMoonJeongEunIcon {
  left: 1248px;
}
.thumbMisorangNatashaSeoIcon,
.thumbMoonJeongEunIcon {
  left: 1344px;
}
.thumbMoonJeongEunIcon {
  left: 1440px;
}
.thumbNohJiSunIcon,
.thumbNohSeongMinIcon,
.thumbOhSuHwaIcon,
.thumbOhYouJinIcon {
  position: absolute;
  top: 225.14px;
  left: 1536px;
  width: 96px;
  height: 112.57px;
  object-fit: cover;
}
.thumbNohSeongMinIcon,
.thumbOhSuHwaIcon,
.thumbOhYouJinIcon {
  left: 1632px;
}
.thumbOhSuHwaIcon,
.thumbOhYouJinIcon {
  left: 1728px;
}
.thumbOhYouJinIcon {
  left: 1824px;
}
.thumbJungYouHyunIcon,
.thumbKangDoHyunIcon,
.thumbKangHyunYiIcon,
.thumbKangMinChaeIcon {
  position: absolute;
  top: 337.71px;
  left: 0;
  width: 96px;
  height: 112.57px;
  object-fit: cover;
}
.thumbKangDoHyunIcon,
.thumbKangHyunYiIcon,
.thumbKangMinChaeIcon {
  left: 96px;
}
.thumbKangHyunYiIcon,
.thumbKangMinChaeIcon {
  left: 192px;
}
.thumbKangMinChaeIcon {
  left: 288px;
}
.thumbKimAhHyunIcon,
.thumbParkJinHyunIcon,
.thumbParkJunHuiIcon,
.thumbParkKeunAhIcon {
  position: absolute;
  top: 337.71px;
  left: 384px;
  width: 96px;
  height: 112.57px;
  object-fit: cover;
}
.thumbParkJinHyunIcon,
.thumbParkJunHuiIcon,
.thumbParkKeunAhIcon {
  left: 480px;
}
.thumbParkJunHuiIcon,
.thumbParkKeunAhIcon {
  left: 576px;
}
.thumbParkKeunAhIcon {
  left: 672px;
}
.thumbParkSaRaIcon,
.thumbParkSaeHaIcon,
.thumbParkSeInIcon,
.thumbParkSeoJinIcon {
  position: absolute;
  top: 337.71px;
  left: 768px;
  width: 96px;
  height: 112.57px;
  object-fit: cover;
}
.thumbParkSaeHaIcon,
.thumbParkSeInIcon,
.thumbParkSeoJinIcon {
  left: 864px;
}
.thumbParkSeInIcon,
.thumbParkSeoJinIcon {
  left: 960px;
}
.thumbParkSeoJinIcon {
  left: 1056px;
}
.thumbParkSoYeonIcon,
.thumbParkYuRimIcon,
.thumbRhoSeungYoungIcon,
.thumbRohKyungMiIcon {
  position: absolute;
  top: 337.71px;
  left: 1152px;
  width: 96px;
  height: 112.57px;
  object-fit: cover;
}
.thumbParkYuRimIcon,
.thumbRhoSeungYoungIcon,
.thumbRohKyungMiIcon {
  left: 1248px;
}
.thumbRhoSeungYoungIcon,
.thumbRohKyungMiIcon {
  left: 1344px;
}
.thumbRohKyungMiIcon {
  left: 1440px;
}
.thumbKimAryeonIcon,
.thumbSaEunHyeIcon,
.thumbSeoEunKyungIcon,
.thumbSeolYeImIcon {
  position: absolute;
  top: 337.71px;
  left: 1536px;
  width: 96px;
  height: 112.57px;
  object-fit: cover;
}
.thumbKimAryeonIcon,
.thumbSeoEunKyungIcon,
.thumbSeolYeImIcon {
  left: 1632px;
}
.thumbKimAryeonIcon,
.thumbSeolYeImIcon {
  left: 1728px;
}
.thumbKimAryeonIcon {
  left: 1824px;
}
.thumbKimJiHuynIcon,
.thumbKimJinKyongIcon,
.thumbShimJiMinIcon,
.thumbShinGyungMinIcon {
  position: absolute;
  top: 450.29px;
  left: 0;
  width: 96px;
  height: 112.57px;
  object-fit: cover;
}
.thumbKimJinKyongIcon,
.thumbShimJiMinIcon,
.thumbShinGyungMinIcon {
  left: 96px;
}
.thumbShimJiMinIcon,
.thumbShinGyungMinIcon {
  left: 192px;
}
.thumbShinGyungMinIcon {
  left: 288px;
}
.thumbShinMiJeongIcon,
.thumbShinSoYoungIcon,
.thumbShonBoRyunIcon,
.thumbSinDoHwiIcon {
  position: absolute;
  top: 450.29px;
  left: 384px;
  width: 96px;
  height: 112.57px;
  object-fit: cover;
}
.thumbShinSoYoungIcon,
.thumbShonBoRyunIcon,
.thumbSinDoHwiIcon {
  left: 480px;
}
.thumbShonBoRyunIcon,
.thumbSinDoHwiIcon {
  left: 576px;
}
.thumbSinDoHwiIcon {
  left: 672px;
}
.thumbSonHyeSeongIcon,
.thumbSongHaNaIcon,
.thumbSongHyeJiIcon,
.thumbSongJiEunIcon {
  position: absolute;
  top: 450.29px;
  left: 768px;
  width: 96px;
  height: 112.57px;
  object-fit: cover;
}
.thumbSongHaNaIcon,
.thumbSongHyeJiIcon,
.thumbSongJiEunIcon {
  left: 864px;
}
.thumbSongHyeJiIcon,
.thumbSongJiEunIcon {
  left: 960px;
}
.thumbSongJiEunIcon {
  left: 1056px;
}
.thumbSongJiYoungIcon,
.thumbSulSuHyunIcon,
.thumbSungKiYounIcon,
.thumbWonLeeSeulIcon {
  position: absolute;
  top: 450.29px;
  left: 1152px;
  width: 96px;
  height: 112.57px;
  object-fit: cover;
}
.thumbSulSuHyunIcon,
.thumbSungKiYounIcon,
.thumbWonLeeSeulIcon {
  left: 1248px;
}
.thumbSungKiYounIcon,
.thumbWonLeeSeulIcon {
  left: 1344px;
}
.thumbWonLeeSeulIcon {
  left: 1440px;
}
.thumbKimJinIcon,
.thumbKimJinYeongIcon,
.thumbKimJooHeeIcon,
.thumbKimMiJooIcon {
  position: absolute;
  top: 450.29px;
  left: 1536px;
  width: 96px;
  height: 112.57px;
  object-fit: cover;
}
.thumbKimJinIcon,
.thumbKimJooHeeIcon,
.thumbKimMiJooIcon {
  left: 1632px;
}
.thumbKimJooHeeIcon,
.thumbKimMiJooIcon {
  left: 1728px;
}
.thumbKimMiJooIcon {
  left: 1824px;
}
.thumbYangYunSeoIcon,
.thumbYeonSunJaneYuIcon,
.thumbYooMiRaIcon {
  position: absolute;
  top: 562.86px;
  left: 0;
  width: 96px;
  height: 112.57px;
  object-fit: cover;
}
.thumbYeonSunJaneYuIcon,
.thumbYooMiRaIcon {
  left: 96px;
}
.thumbYooMiRaIcon {
  left: 192px;
}
.thumbYooSeulJiIcon,
.thumbYouSuMinIcon,
.thumbYuSeJinIcon,
.thumbYunJiUnIcon,
.thumbYunJinEIcon {
  position: absolute;
  top: 562.86px;
  left: 288px;
  width: 96px;
  height: 112.57px;
  object-fit: cover;
}
.thumbYouSuMinIcon,
.thumbYuSeJinIcon,
.thumbYunJiUnIcon,
.thumbYunJinEIcon {
  left: 384px;
}
.thumbYuSeJinIcon,
.thumbYunJiUnIcon,
.thumbYunJinEIcon {
  left: 480px;
}
.thumbYunJiUnIcon,
.thumbYunJinEIcon {
  left: 576px;
}
.thumbYunJinEIcon {
  left: 672px;
}
.thumbKimSeoHeeIcon,
.thumbKimSeoYoungIcon,
.thumbKimSoRaIcon,
.thumbYunMinHyeokIcon {
  position: absolute;
  top: 562.86px;
  left: 768px;
  width: 96px;
  height: 112.57px;
  object-fit: cover;
}
.thumbKimSeoHeeIcon,
.thumbKimSeoYoungIcon,
.thumbKimSoRaIcon {
  left: 864px;
}
.thumbKimSeoYoungIcon,
.thumbKimSoRaIcon {
  left: 960px;
}
.thumbKimSoRaIcon {
  left: 1056px;
}
.thumbKimSoYoungKellyIcon,
.thumbKimSuJungIcon,
.thumbKimSuZyIcon,
.thumbKimWooJoongIcon {
  position: absolute;
  top: 562.86px;
  left: 1152px;
  width: 96px;
  height: 112.57px;
  object-fit: cover;
}
.thumbKimSuJungIcon,
.thumbKimSuZyIcon,
.thumbKimWooJoongIcon {
  left: 1248px;
}
.thumbKimSuZyIcon,
.thumbKimWooJoongIcon {
  left: 1344px;
}
.thumbKimWooJoongIcon {
  left: 1440px;
}
.thumbKimYooJeongIcon,
.thumbKoYuKyeongIcon,
.thumbKongHeeYeonIcon,
.thumbKongInHyeIcon {
  position: absolute;
  top: 562.86px;
  left: 1536px;
  width: 96px;
  height: 112.57px;
  object-fit: cover;
}
.thumbKoYuKyeongIcon,
.thumbKongHeeYeonIcon,
.thumbKongInHyeIcon {
  left: 1632px;
}
.thumbKongHeeYeonIcon,
.thumbKongInHyeIcon {
  left: 1728px;
}
.thumbKongInHyeIcon {
  left: 1824px;
}
.thumbJeonLeeSeulIcon,
.thumbJungSiYoonIcon,
.thumbJungYoonAIcon,
.thumbJungYoonJiIcon {
  position: absolute;
  top: 675.43px;
  left: 0;
  width: 96px;
  height: 112.57px;
  object-fit: cover;
}
.thumbJungSiYoonIcon,
.thumbJungYoonAIcon,
.thumbJungYoonJiIcon {
  left: 96px;
}
.thumbJungYoonAIcon,
.thumbJungYoonJiIcon {
  left: 192px;
}
.thumbJungYoonJiIcon {
  left: 288px;
}
.thumbKimHyoJuIcon,
.thumbKimHyoMiIcon,
.thumbKimHyunAhIcon,
.thumbKimSeHeeIcon {
  position: absolute;
  top: 675.43px;
  left: 384px;
  width: 96px;
  height: 112.57px;
  object-fit: cover;
}
.thumbKimHyoMiIcon,
.thumbKimHyunAhIcon,
.thumbKimSeHeeIcon {
  left: 480px;
}
.thumbKimHyunAhIcon,
.thumbKimSeHeeIcon {
  left: 576px;
}
.thumbKimSeHeeIcon {
  left: 672px;
}
.thumbJangWuJuIcon,
.thumbKimSeYoungIcon,
.thumbLeeJiYoungIcon,
.thumbYangseonghyunIcon {
  position: absolute;
  top: 675.43px;
  left: 768px;
  width: 96px;
  height: 112.57px;
  object-fit: cover;
}
.thumbJangWuJuIcon,
.thumbLeeJiYoungIcon,
.thumbYangseonghyunIcon {
  left: 864px;
}
.thumbJangWuJuIcon,
.thumbLeeJiYoungIcon {
  left: 960px;
}
.thumbJangWuJuIcon {
  left: 1056px;
}
.thumbJeonEunJuIcon,
.thumbJungMuRinIcon,
.thumbKimHyeYeonIcon,
.thumbKimRaylaIcon {
  position: absolute;
  top: 675.43px;
  left: 1152px;
  width: 96px;
  height: 112.57px;
  object-fit: cover;
}
.thumbJeonEunJuIcon,
.thumbKimHyeYeonIcon,
.thumbKimRaylaIcon {
  left: 1248px;
}
.thumbJeonEunJuIcon,
.thumbKimRaylaIcon {
  left: 1344px;
}
.thumbJeonEunJuIcon {
  left: 1440px;
}
.thumbJangHanAIcon,
.thumbJungMinHyangIcon,
.thumbKimHueJuIcon,
.thumbLeeJiYoenIcon {
  position: absolute;
  top: 675.43px;
  left: 1536px;
  width: 96px;
  height: 112.57px;
  object-fit: cover;
}
.thumbJangHanAIcon,
.thumbJungMinHyangIcon,
.thumbKimHueJuIcon {
  left: 1632px;
}
.thumbJungMinHyangIcon,
.thumbKimHueJuIcon {
  left: 1728px;
}
.thumbKimHueJuIcon {
  left: 1824px;
}
.imgProfilesChild {
  background-color: var(--gray-900);
  opacity: 0.7;
}
.imgProfiles,
.imgProfilesChild {
  position: absolute;
  top: 0;
  left: 0;
  width: 1920px;
  height: 788px;
}
.kfbaContainer {
  position: absolute;
  top: 330px;
  left: calc(50% - 259px);
  line-height: 135%;
  text-transform: uppercase;
}
.section03 {
  position: absolute;
  top: 1774px;
  left: 0;
  width: 1920px;
  height: 788px;
  text-align: center;
  font-size: 32px;
}
.kfba1 {
  position: relative;
  line-height: 125%;
}
.b,
.mc {
  font-family: var(--font-montserrat);
}
.mc {
  font-weight: 500;
  color: var(--gray-900);
}
.kfbaMcContainer {
  position: relative;
  font-size: var(--font-size-xl);
  line-height: 150%;
  color: var(--color-darkslategray);
}
.title2 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-xs);
}
.buttonPlus {
  position: relative;
  width: 66px;
  height: 66px;
}
.title1 {
  width: 1920px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  padding: 0 var(--padding-221xl);
  box-sizing: border-box;
}
.imgTodayMc01Icon {
  position: relative;
  width: 345px;
  height: 460px;
  object-fit: cover;
  object-position: top;
}
.div14,
.jungMinHyang {
  position: relative;
  line-height: 150%;
}
.div14 {
  font-weight: 600;
}
.jungMinHyang {
  font-size: var(--font-size-base);
  font-family: var(--font-montserrat);
  color: var(--gray-700);
}
.container,
.name {
  display: flex;
  justify-content: flex-start;
}
.name {
  flex-direction: row;
  align-items: center;
  gap: var(--gap-5xs);
  font-size: var(--font-size-5xl);
  color: var(--gray-900);
}
.container {
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(--color-white);
  width: 345px;
  flex-direction: column;
  align-items: flex-start;
  padding: var(--padding-9xl) 0 var(--padding-5xl) var(--padding-11xl);
  box-sizing: border-box;
  gap: var(--gap-xs);
}
.contents1 {
  position: relative;
  width: 345px;
  height: 120px;
}
.cardHover {
  box-shadow: 8px 8px 20px rgba(17, 17, 17, 0.08);
}
.card,
.cardHover {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.card:hover {
  cursor: pointer;
  box-shadow: 8px 8px 20px rgba(17, 17, 17, 0.08);
  transform: scale(1.02);
}
.imgTodayMc03Icon {
  position: relative;
  width: 345px;
  height: 391px;
  object-fit: cover;
}
.cards,
.carousel,
.section02 {
  display: flex;
  justify-content: flex-start;
}
.cards {
  flex-direction: row;
  align-items: flex-start;
  gap: 20px;

  a {
    text-decoration: none;
    color: var(--gray-500);
  }
}
.carousel,
.section02 {
  flex-direction: column;
}
.carousel {
  width: 1920px;
  align-items: center;
  font-size: var(--font-size-xl);
  color: var(--color-silver-100);
}
.section02 {
  position: absolute;
  top: 800px;
  left: 0;
  background-color: var(--color-gray-100);
  align-items: flex-start;
  padding: 120px 0;
  gap: 48px;
  font-size: var(--font-size-33xl);
  color: var(--gray-900);
}
.logoGrayIcon {
  position: relative;
  width: 70px;
  height: 34px;
}
.history {
  position: relative;
  line-height: 100%;
  text-transform: uppercase;
  font-weight: 600;
}
.menu1,
.menu2 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 38px;

  a {
    cursor: pointer;
    text-decoration: none;
    color: var(--gray-900);
  }
}
.menu1 {
  align-items: center;
  gap: 60px;
}
.kfba4 {
  color: var(--color-darkslategray);
}
.copyright2013Container,
.div21 {
  position: relative;
  line-height: 100%;
}
.div21 {
  text-decoration: underline;
  font-weight: 500;
}
.buttons {
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-xs);
  color: var(--gray-900);
  font-family: var(--font-pretendard);
}
.buttons,
.footer,
.info {
  display: flex;
  flex-direction: row;
}
.info {
  align-items: flex-end;
  justify-content: flex-start;
  gap: 19px;
  color: var(--gray-700);
}
.footer {
  bottom: 0;
  left: 0;
  box-sizing: border-box;
  width: 1920px;
  align-items: center;
  justify-content: space-between;
  padding: 20px var(--padding-221xl);
  color: var(--gray-900);
  font-family: var(--font-montserrat);
  border-top: 1px solid var(--gray-900);
}
.b1,
.bg4,
.footer {
  position: absolute;
}
.bg4 {
  top: 0;
  left: 0;
  border-radius: 50%;
  background-color: var(--gray-900);
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.1);
  width: 64px;
  height: 64px;
}
.b1 {
  top: 25px;
  left: 7px;
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
  text-transform: uppercase;
}
.pcMainHeader {
  font-size: var(--font-size-sm);
  color: var(--color-white);
  font-family: var(--font-pretendard);
  width: 1920px;
  height: 82px;
  margin: 0 auto;
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;
}
.pcMain {
  font-size: var(--font-size-sm);
  color: var(--color-white);
  font-family: var(--font-pretendard);
  width: 1920px;
  height: 3136px;
  margin: 0 auto;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}
.pcMainContainer {
  width: 1920px;
  margin: 0 auto;
  position: relative;
  overflow: hidden;

  img {
    -webkit-user-select: none; /* 크롬, 사파리, 오페라 */
    -moz-user-select: none; /* 파이어폭스 */
    -ms-user-select: none; /* 인터넷 익스플로러, 엣지 */
    user-select: none; /* 표준 문법 */
    pointer-events: none; /* 마우스 이벤트 차단 */
  }
}

.section01Bg {
  position: absolute;
  width: 100%;
  height: 800px;
  top: 0;
  transition: background-color 1s ease-in-out; /* 배경색 변경 애니메이션 */
}

.bgColor1 {
  background-color: transparent;
}

.bgColor2 {
  background-color: #232323; /* 두 번째 이미지에 해당하는 배경색 */
}

.bgMain01Icon {
  position: absolute;
  top: 0;
  left: 0;
  width: 1920px;
  height: 800px;
  transition: background-image 1s ease-in-out;
  background-size: 100% 100%; /* 이미지가 요소의 전체 크기를 채우도록 설정 */
  background-repeat: no-repeat; /* 이미지 반복 방지 */
  background-size: cover;
  background-position: center;
}
.bgImage1 {
  background-image: url('./bgmain01.png');
  background-size: 100% 100%; /* 이미지가 요소의 전체 크기를 채우도록 설정 */
  background-repeat: no-repeat; /* 이미지 반복 방지 */
}
.bgImage2 {
  background-image: url('./bgmain02.png');
  background-size: 100% 100%; /* 이미지가 요소의 전체 크기를 채우도록 설정 */
  background-repeat: no-repeat; /* 이미지 반복 방지 */
}
.bgImage3 {
  background-image: url('./bgmain02.png');
  background-size: 100% 100%; /* 이미지가 요소의 전체 크기를 채우도록 설정 */
  background-repeat: no-repeat; /* 이미지 반복 방지 */
}
.bgImage4 {
  background-image: url('./bgmain02.png');
  background-size: 100% 100%; /* 이미지가 요소의 전체 크기를 채우도록 설정 */
  background-repeat: no-repeat; /* 이미지 반복 방지 */
}
.bgImage5 {
  background-image: url('./bgmain02.png');
  background-size: 100% 100%; /* 이미지가 요소의 전체 크기를 채우도록 설정 */
  background-repeat: no-repeat; /* 이미지 반복 방지 */
}
.bgImage6 {
  background-image: url('./bgmain02.png');
  background-size: 100% 100%; /* 이미지가 요소의 전체 크기를 채우도록 설정 */
  background-repeat: no-repeat; /* 이미지 반복 방지 */
}
.bgImage7 {
  background-image: url('./bgmain02.png');
  background-size: 100% 100%; /* 이미지가 요소의 전체 크기를 채우도록 설정 */
  background-repeat: no-repeat; /* 이미지 반복 방지 */
}
.bgImage8 {
  background-image: url('./bgmain02.png');
  background-size: 100% 100%; /* 이미지가 요소의 전체 크기를 채우도록 설정 */
  background-repeat: no-repeat; /* 이미지 반복 방지 */
}
.bgImage9 {
  background-image: url('./bgmain02.png');
  background-size: 100% 100%; /* 이미지가 요소의 전체 크기를 채우도록 설정 */
  background-repeat: no-repeat; /* 이미지 반복 방지 */
}
.bgImage10 {
  background-image: url('./bgmain02.png');
  background-size: 100% 100%; /* 이미지가 요소의 전체 크기를 채우도록 설정 */
  background-repeat: no-repeat; /* 이미지 반복 방지 */
}
.progressBarBox {
  background-color: red;
  height: 100px;
  width: 300px;
  .progressBar {
    background-color: blue;
    height: 100%;
  }
}

.section02Bg {
  background-color: var(--color-gray-100);
  position: absolute;
  width: 100%;
  height: 974px;
  top: 800px;
}
.section03Bg {
  background-color: var(--gray-900);
  opacity: 0.7;
  position: absolute;
  width: 100%;
  height: 788px;
  top: 1774px;
}
.footerBg {
  position: absolute;
  top: 3063px;
  width: 100%;
  height: 1px;
  background-color: var(--gray-900);
}
@media screen and (min-width: 1480px) and (max-width: 1920px) {
  .pcMain {
    width: 1920px;
    height: 3136px;
    margin: 0 auto;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
  }
  .pcMainContainer {
    width: 100%;
    position: relative;
    overflow: hidden;
  }
}

@media screen and (max-width: 1480px) {
  .pcMain {
    width: 1920px;
    height: 3136px;
    margin: 0 auto;
    /* margin-left: -220px; */
    position: relative;
    clip-path: inset(0 calc(220px) 0 calc(220px));
  }
  .pcMainContainer {
    width: 1480px;
    margin: 0 auto;
    position: relative;
    overflow: hidden;
  }
  .footer {
    border-top: 1px solid var(--gray-900);
  }
  .section01Bg,
  .section02Bg,
  .section03Bg,
  .footerBg {
    display: none;
  }
}

/* start header */
.pcHeaderContainer {
  width: 100%;
  margin: 0 auto;
  position: fixed;
  overflow: hidden;
  top: 0;
  z-index: 10;
  height: 82px;
  color: var(--color-white);
  transition: background-color 1s;
}
.pcHeader {
  font-size: var(--font-size-sm);
  font-family: var(--font-pretendard);
  width: 1920px;
  height: 82px;
  margin: 0 auto;
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;
}
.pcHeaderScrolled {
  background-color: white;
  color: var(--gray-900) !important;
}
.gnbWhite {
  position: fixed;
  top: 0;
  left: 0;
  width: 1920px;
  justify-content: space-between;
  padding: var(--padding-base) var(--padding-221xl);
  box-sizing: border-box;
  font-size: var(--font-size-base);
  font-family: var(--font-montserrat);
  z-index: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.logoWhiteIcon {
  position: relative;
  width: 102px;
  height: 50px;
}
.menu {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 56px;
  a {
    color: inherit;
    text-decoration: none;
  }
}
.home {
  position: relative;
  text-transform: uppercase;
}
@media screen and (min-width: 1480px) and (max-width: 1920px) {
  .pcHeader {
    width: 1920px;
    height: 100px;
    margin: 0 auto;
    position: fixed;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }
}
@media screen and (max-width: 1480px) {
  .pcHeader {
    width: 100%;
    margin: 0 auto;
  }
  .pcHeaderContainer {
    width: 100%;
  }

  .gnbWhite {
    width: 100%;
    padding: var(--padding-base) 20px;
    z-index: 10;
  }
}

/* fadeUp */
/* @keyframes fadeUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.div3 {
  animation: fadeUp 1s ease-out;
}
.div4 {
  opacity: 0;
  animation: fadeUp 1s ease-out 1s forwards;
  animation-delay: 0.2s;
} */

/* floatingButton */
.button,
.buttonKakaotalk {
  position: relative;
  width: 64px;
  height: 64px;
}
.buttonKakaotalk {
  width: 72px;
  height: 72px;
}
.floatingButton {
  position: fixed;
  bottom: 40px;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xs);
}
.bg4 {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 50%;
  background-color: var(--gray-900);
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.1);
  width: 64px;
  height: 64px;
}
.b1 {
  position: absolute;
  top: 25px;
  left: 7px;
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
  text-transform: uppercase;
}
.btnStyle {
  background: none;
  border: none;
  padding: 0;
}
