/* floatingButton start */
.button,
.buttonKakaotalk {
  position: relative;
  width: 64px;
  height: 64px;
}
.buttonKakaotalk {
  width: 72px;
  height: 72px;
}
.floatingButton {
  position: fixed;
  bottom: 40px;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xs);
}
.bg4 {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 50%;
  background-color: var(--gray-900);
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.1);
  width: 64px;
  height: 64px;
}
.b1 {
  position: absolute;
  top: 25px;
  left: 7px;
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
  text-transform: uppercase;
}
.btnStyle {
  background: none;
  border: none;
  padding: 0;
}
/* floatingButton end */
/* header start */
.header {
  position: fixed;
  display: flex;
  width: 100%;
  padding: 24px 20px 8px 20px;
  justify-content: space-between;
  align-items: center;
  z-index: 99;
  .menuIcon {
    background: none;
    border: none;
    padding: 0;
    img {
      width: 24px;
      height: 24px;
    }
  }
}
.headerScrolled {
  background-color: white;
  color: var(--gray-900);
}
.headerBg {
  visibility: hidden;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  opacity: 0;
  transition: opacity 0.5s ease;
  padding: 24px 20px 8px 20px;
  .menuIcon {
    background: none;
    border: none;
    padding: 0;
    float: right;
    line-height: 36px;
    img {
      width: 24px;
      height: 24px;
    }
  }
  .menu {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 56px;
    align-items: center;
    margin-top: 114px;
    a {
      color: var(--gray-900, #111);
      font-family: Montserrat;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      text-transform: uppercase;
      text-decoration: none;
      cursor: pointer;
    }
  }
}
.headerBg.active {
  visibility: visible;
  opacity: 1;
  z-index: 100;
}
/* header end */
/* footer start */
.footer {
  display: flex;
  width: 100%;
  padding: 40px 20px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 32px;
  .logo {
    width: 70px;
    height: 34px;
  }
  .menu {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    a {
      color: var(--gray-900, #111);
      font-family: Montserrat;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 100%; /* 14px */
      text-transform: uppercase;
      text-decoration: none;
    }
  }
}
.copy {
  display: flex;
  width: 100%;
  padding: 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  .copyText {
    color: var(--gray-900, #111);
    font-family: Pretendard;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
    text-decoration-line: underline;
    display: flex;
    gap: 11px;
  }
  .copyright {
    color: #939393;
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    b {
      font-weight: 700;
      color: #111;
    }
  }
}
/* footer end */
.container {
  .containerTitleWrapper {
    padding: 120px 20px 40px 20px;
    .title {
      color: var(--gray-900, #111);
      font-family: Montserrat;
      font-size: 28px;
      font-style: normal;
      font-weight: 400;
      line-height: 125%;
      text-transform: uppercase;
    }
    .subTitle {
      margin-top: 4px;
      color: var(--gray-800, #494949);
      font-family: Pretendard;
      font-size: 14px;
      font-style: normal;
      font-weight: 300;
      line-height: 150%;
      b {
        color: var(--gray-800, #111);
        font-family: Pretendard;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%;
      }
    }
  }

  .tab {
    margin: 0 20px;
    display: flex;
    padding-bottom: 22px;
  }
  .tabTitle,
  .tabTitle2 {
    width: 100%;
    display: flex;
    width: 720px;
    padding: 12px 0px;
    justify-content: center;
    align-items: center;
    gap: 5px;
    background: none;
    border: none;
    font-weight: 600;
    font-size: 12px;
    color: var(--gray-600, #939393);
    border-bottom: 1px solid var(--gray-400, #d0d0d0);
  }
  .tabTitle2 {
  }
  .tabActive {
    border: 1px solid var(--gray-900, #111);
    border-bottom: none;
    color: var(--gray-900, #111);
  }

  .searchBar1 {
    border-bottom: 1px solid var(--color-gray-300);
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: var(--padding-xs) var(--padding-base);
    gap: var(--gap-5xs);
    font-size: var(--font-size-base);
    margin: 0 20px;
    margin-bottom: 20px;

    button {
      border: none;
      background-color: transparent;
      padding: 0;
    }
  }

  .div29 {
    flex: 1;
    position: relative;
    line-height: 100%;
    display: flex;
    align-items: center;
    height: 20px;

    input {
      font-family: Pretendard;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 100%;
      font-size: 16px !important;
      border: none;
      padding: 0.25rem 0 !important;
    }
    input:focus {
      outline: none;
      border-color: none;
      box-shadow: none;
    }
  }

  .membersProfileListEmpty {
    .noDataText {
      color: var(--gray-900);
      font-size: 1rem;
      font-weight: 500;
      line-height: 175%;
      text-align: center;
    }
    .noDataButton {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: var(--gap-5xs);
      margin-top: 20px;
    }
    button {
      background-color: var(--gray-900);
      border: 1px solid var(--gray-900);
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      padding: var(--padding-base) var(--padding-17xl);
      text-align: center;
      font-size: var(--font-size-base);
      color: var(--color-white);
      overflow: hidden;
      cursor: pointer;
      height: 52px;
      width: 180px;
    }
    button:hover {
      color: var(--gray-900) !important;
      border: 1px solid var(--gray-900) !important;
      background-color: var(--color-white);
    }
    a {
      text-decoration: none;
      color: var(--color-white);
    }
  }

  .membersProfileList {
    a {
      text-decoration: none;
    }
  }
  .membersProfileWrapper {
    padding: 12px 0;
    margin: 0 20px;
    display: flex;
    gap: 16px;
    border-bottom: 1px solid #eee;

    .membersProfileImage {
      min-width: 68px;
      max-width: 68px;
      height: 68px;
      object-fit: cover;
    }
    .membersProfileNotice {
      color: var(--color-darkslategray);
      font-size: 12px;
      position: absolute;
      margin: 0;
      padding: 2px 4px;
      border: 1px solid #d0d0d0;
    }
    .membersProfileNameWrapper {
    }
    .membersProfileTitle {
      color: var(--gray-900, #111);
      font-family: Pretendard;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 150%;
    }
    .membersProfileSubTitle {
      color: var(--gray-700, #767676);
      font-family: Pretendard;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
      display: -webkit-box;
      -webkit-line-clamp: 2; /* 텍스트를 3줄로 제한 */
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis; /* 텍스트가 오버플로 될 때 "..."으로 표시 */
    }
    .membersProfileFooter {
      margin-top: 12px;
      display: flex;
    }
    .membersProfileDate {
      color: var(--gray-800, #494949);
      font-family: Montserrat;
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: 100%;
    }
    .membersProfileMore {
      position: absolute;
      right: 34px;
      color: var(--gray-800, #494949);
      font-family: Montserrat;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 100%; /* 12px */
      text-decoration-line: underline;
    }
    .membersProfilePlus {
      position: absolute;
      right: 20px;
      width: 12px;
      height: 12px;
    }
  }

  .parent {
    width: 100%;
    text-align: center;
    margin-bottom: 40px;

    button {
      color: var(--gray-600, #939393);
      font-family: Montserrat;
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      background: none;
      border: none;
      border-bottom: 1px solid var(--gray-400, #d0d0d0);
      margin-left: 4px;
      margin-right: 4px;
      padding: 7px 12px;
      margin-top: 28px;
    }
    .active {
      color: #000;
      font-weight: 700;
      border: 1px solid var(--gray-900, #111);
    }
  }
}
