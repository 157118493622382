/* floatingButton start */
.button,
.buttonKakaotalk {
  position: relative;
  width: 64px;
  height: 64px;
}
.buttonKakaotalk {
  width: 72px;
  height: 72px;
}
.floatingButton {
  position: fixed;
  z-index: 1;
  bottom: 40px;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xs);
}
.bg4 {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 50%;
  background-color: var(--gray-900);
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.1);
  width: 64px;
  height: 64px;
}
.b1 {
  position: absolute;
  top: 25px;
  left: 7px;
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
  text-transform: uppercase;
}
.btnStyle {
  background: none;
  border: none;
  padding: 0;
}
/* floatingButton end */
/* header start */
.header {
  position: fixed;
  display: flex;
  width: 100%;
  padding: 24px 20px 8px 20px;
  justify-content: space-between;
  align-items: center;
  z-index: 99;
  .menuIcon {
    background: none;
    border: none;
    padding: 0;
    img {
      width: 24px;
      height: 24px;
    }
  }
}
.headerScrolled {
  background-color: white;
  color: var(--gray-900);
}
.headerBg {
  visibility: hidden;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  opacity: 0;
  transition: opacity 0.5s ease;
  padding: 24px 20px 8px 20px;
  .menuIcon {
    background: none;
    border: none;
    padding: 0;
    float: right;
    line-height: 36px;
    img {
      width: 24px;
      height: 24px;
    }
  }
  .menu {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 56px;
    align-items: center;
    margin-top: 114px;
    a {
      color: var(--gray-900, #111);
      font-family: Montserrat;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      text-transform: uppercase;
      text-decoration: none;
      cursor: pointer;
    }
  }
}
.headerBg.active {
  visibility: visible;
  opacity: 1;
  z-index: 100;
}
/* header end */
/* footer start */
.footer {
  display: flex;
  width: 100%;
  padding: 40px 20px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 32px;
  .logo {
    width: 70px;
    height: 34px;
  }
  .menu {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    a {
      color: var(--gray-900, #111);
      font-family: Montserrat;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 100%; /* 14px */
      text-transform: uppercase;
      text-decoration: none;
    }
  }
}
.copy {
  display: flex;
  width: 100%;
  padding: 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  .copyText {
    color: var(--gray-900, #111);
    font-family: Pretendard;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
    text-decoration-line: underline;
    display: flex;
    gap: 11px;
  }
  .copyright {
    color: #939393;
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    b {
      font-weight: 700;
      color: #111;
    }
  }
}
/* footer end */
.container {
  .bgWrrapper {
    position: fixed;
    z-index: 19;
    background: white;
    padding-top: 120px;
    height: 175px;
    width: 100%;
  }
  .nameWrapper {
    position: fixed;
    padding-top: 120px;
    display: flex;
    gap: 8px;
    margin-left: 20px;
    z-index: 20;
    background: white;
    .membersProfileName {
      color: #2b2b2b;
      font-family: Pretendard;
      font-size: 32px;
      font-style: normal;
      font-weight: 400;
      line-height: 32px;
    }
    .membersProfileEnName {
      color: var(--gray-600, #939393);
      font-family: Montserrat;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px;
      margin-top: 16px;
    }
  }

  .formBorder {
    position: fixed;
    top: 175px;
    z-index: 19;
    width: calc(100% - 40px);
    margin-left: 20px;
    height: 1px;
    background: #e9e9e9;
  }
  .formBorderScrolled {
  }
  .imgWrapper {
    padding-top: 195px;
    padding-left: 20px;
    padding-right: 20px;
    width: 100%;
  }
  .imgSlider {
    width: 100%;
    position: relative;
    aspect-ratio: 3 / 4;
    overflow: hidden;
    border: none;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: top;
    }
  }
  .indicatorImage {
    /* position: absolute;
    top: 817px;
    left: 0; */
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10px;
    width: 600px;
  }
  .indicator1,
  .indicator2 {
    position: relative;
    border-radius: 50%;
    background-color: var(--color-gray-400);
    width: 8px;
    height: 8px;
    cursor: pointer;
  }
  .indicator2 {
    background-color: var(--color-gainsboro-100);
  }

  .imageArrowLeft {
    background: none;
    border: none;
    padding: 10px;
    img {
      width: 12px;
      height: 12px;
      vertical-align: revert;
    }
  }
  .imageArrowRight {
    background: none;
    border: none;
    padding: 10px;
    img {
      width: 12px;
      height: 12px;
      vertical-align: revert;
    }
  }
  .imageFooter {
    display: flex;
    justify-content: center;
    margin-bottom: 45px;

    .dotActive {
      opacity: 1 !important;
    }
    .imagePaging {
      display: flex;
      gap: 8px;
      align-items: center;
      div {
        width: 8px;
        height: 8px;
        border-radius: 50%;
        opacity: 0.2;
        background: #000;
      }
    }
  }

  /* pagination start */
  .pagination {
    width: 134px;
    background-color: rgba(17, 17, 17, 0.6);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 10px;
    gap: var(--gap-5xs);
    color: var(--color-gray-100);
    position: absolute;
    z-index: 10;
    margin-left: 20px;
    margin-top: -44px;
  }
  .iconProfileArrowLeft {
    position: relative;
    width: 24px;
    height: 24px;
    cursor: pointer;
  }
  .iconProfileArrowRight {
    position: relative;
    width: 24px;
    height: 24px;
    cursor: pointer;
  }
  .arrowIcon,
  .bg1,
  .membersParent {
    position: absolute;
  }
  .bg1 {
    top: 0;
    left: 0;
    width: 24px;
    height: 24px;
    opacity: 0.6;
  }
  .page,
  .page2,
  .slash {
    display: flex;
    flex-direction: row;
    z-index: 999;
  }
  .page2 {
    align-items: center;
    justify-content: center;
    color: var(--color-gray-200);
  }
  .page {
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-9xs);
  }
  .arrowIcon {
    top: 1.65px;
    left: 6.29px;
    width: 11.06px;
    height: 20.71px;
  }
  .arrowIcon1 {
    position: absolute;
    top: 1.65px;
    left: 6.65px;
    width: 11.06px;
    height: 20.71px;
  }
  /* pagination end */

  .videoIcon {
    margin: 40px 20px 38px 20px;
    position: relative;
    height: 300px;

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    .videoArrowLeft {
      background: none;
      border: none;
      padding: 10px;
      img {
        width: 12px;
        height: 12px;
        vertical-align: revert;
      }
    }
    .videoArrowRight {
      background: none;
      border: none;
      padding: 10px;
      img {
        width: 12px;
        height: 12px;
        vertical-align: revert;
      }
    }
    .youtubeFooter {
      display: flex;
      justify-content: center;

      .dotActive {
        opacity: 1 !important;
      }
      .youtubePaging {
        display: flex;
        gap: 8px;
        align-items: center;
        div {
          width: 8px;
          height: 8px;
          border-radius: 50%;
          opacity: 0.2;
          background: #000;
        }
      }
    }
  }
  .careerTitle {
    color: var(--gray-900, #111);
    font-family: Pretendard;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 125%;
    margin: 0 20px;
    margin-top: 30px;
  }
  .careerSubTitle {
    color: var(--gray-800, #494949);
    font-family: Pretendard;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 175%;
    margin-top: 12px;
    ul {
      padding-left: 40px !important;
      padding-right: 20px;
    }
  }
}

.contactWrapper {
  padding: 84px 20px 20px 20px;
  .title {
    color: var(--gray-900, #111);
    font-family: Montserrat;
    font-size: 64px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    text-transform: uppercase;
  }
  .subTitle {
    color: #111;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 150%;
    position: absolute;
    margin-left: 105px;
    margin-top: 72px;
  }
}
.contactGif {
  width: 132px;
  float: right;
  margin-right: 20px;
}
.contactForm {
  margin-top: 20px;

  .placeholder {
    border-top: 1px solid var(--gray-900, #111);
    padding: 0 8px;
    input {
      border: none;
      margin: 0;
    }
    input:focus {
      outline: none;
      border-color: none;
      box-shadow: none;
    }

    textarea {
      border: none;
      margin: 0;
    }
    textarea:focus {
      outline: none;
      border-color: none;
      box-shadow: none;
    }
  }
  .checkInfo {
    display: flex;
    padding: 16px 20px 34px 16px;
    border-top: 1px solid var(--gray-900, #111);
    .checkText {
      line-height: 14px;
    }
    .under {
      text-decoration: underline;
      color: #111;
      font-family: Pretendard;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 100%; /* 14px */
      text-decoration-line: underline;
    }
    .underText {
      color: #747373;
      font-family: Pretendard;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 100%;
    }
  }
  .checkboxDefault {
    position: relative;
    margin-right: 12px;
  }
  .formBtn {
    cursor: pointer;
    margin: 0 20px;
    margin-bottom: 40px;
    background: var(--gray-900, #111);
    display: flex;
    padding: 16px 40px;
    justify-content: center;
    align-items: center;
    gap: 16px;
    div {
      color: #fff;
      font-family: Pretendard;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 100%;
    }
    img {
      width: 20px;
      height: 20px;
    }
  }
  .formBtnFooter {
    border-bottom: 1px solid var(--gray-900, #111);
  }
}
