/* floatingButton start */
.button,
.buttonKakaotalk {
  position: relative;
  width: 64px;
  height: 64px;
}
.buttonKakaotalk {
  width: 72px;
  height: 72px;
}
.floatingButton {
  position: fixed;
  bottom: 40px;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xs);
}
.bg4 {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 50%;
  background-color: var(--gray-900);
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.1);
  width: 64px;
  height: 64px;
}
.b1 {
  position: absolute;
  top: 25px;
  left: 7px;
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
  text-transform: uppercase;
}
.btnStyle {
  background: none;
  border: none;
  padding: 0;
}
/* floatingButton end */
/* header start */
.header {
  position: fixed;
  display: flex;
  width: 100%;
  padding: 24px 20px 8px 20px;
  justify-content: space-between;
  align-items: center;
  z-index: 99;
  .menuIcon {
    background: none;
    border: none;
    padding: 0;
    img {
      width: 24px;
      height: 24px;
    }
  }
}
.headerScrolled {
  background-color: white;
  color: var(--gray-900);
}
.headerBg {
  visibility: hidden;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  opacity: 0;
  transition: opacity 0.5s ease;
  padding: 24px 20px 8px 20px;
  .menuIcon {
    background: none;
    border: none;
    padding: 0;
    float: right;
    line-height: 36px;
    img {
      width: 24px;
      height: 24px;
    }
  }
  .menu {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 56px;
    align-items: center;
    margin-top: 114px;
    a {
      color: var(--gray-900, #111);
      font-family: Montserrat;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      text-transform: uppercase;
      text-decoration: none;
      cursor: pointer;
    }
  }
}
.headerBg.active {
  visibility: visible;
  opacity: 1;
  z-index: 100;
}
/* header end */
/* footer start */
.footer {
  display: flex;
  width: 100%;
  padding: 40px 20px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 32px;
  .logo {
    width: 70px;
    height: 34px;
  }
  .menu {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    a {
      color: var(--gray-900, #111);
      font-family: Montserrat;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 100%; /* 14px */
      text-transform: uppercase;
      text-decoration: none;
    }
  }
}
.copy {
  display: flex;
  width: 100%;
  padding: 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  .copyText {
    color: var(--gray-900, #111);
    font-family: Pretendard;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
    text-decoration-line: underline;
    display: flex;
    gap: 11px;
  }
  .copyright {
    color: #939393;
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    b {
      font-weight: 700;
      color: #111;
    }
  }
}
/* footer end */
.container {
  .containerTitleWrapper {
    padding: 120px 20px 28px 20px;
    .title {
      color: var(--gray-900, #111);
      font-family: Montserrat;
      font-size: 28px;
      font-style: normal;
      font-weight: 400;
      line-height: 125%;
      text-transform: uppercase;
    }
    .subTitle {
      margin-top: 4px;
      color: var(--gray-800, #494949);
      font-family: Pretendard;
      font-size: 14px;
      font-style: normal;
      font-weight: 300;
      line-height: 150%;
      b {
        color: var(--gray-800, #111);
        font-family: Pretendard;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%;
      }
    }
  }
  .searchBar1 {
    border-bottom: 1px solid var(--color-gray-300);
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: var(--padding-xs) var(--padding-base);
    gap: var(--gap-5xs);
    font-size: var(--font-size-base);
    margin: 0 20px;

    button {
      border: none;
      background-color: transparent;
      padding: 0;
    }
  }

  .div29 {
    flex: 1;
    position: relative;
    line-height: 100%;
    display: flex;
    align-items: center;
    height: 20px;

    input {
      font-family: Pretendard;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 100%;
      font-size: 16px !important;
      border: none;
      padding: 0.25rem 0 !important;
    }
    input:focus {
      outline: none;
      border-color: none;
      box-shadow: none;
    }
  }

  .dropdown {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    font-size: var(--font-size-base);
    margin: 16px 20px 36px 16px;
  }
  .dropdownHover {
    color: var(--gray-900, #111);
    font-family: Pretendard;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    border: 1px solid var(--gray-900, #111);
    padding: 12px 16px;
    position: relative;
    cursor: pointer;
    flex: 1;
    position: relative;
    line-height: 100%;
    display: flex;
    align-items: center;
    height: 44px;
    justify-content: space-between;
  }

  .searchBar {
    background-color: var(--gray-900);
    box-shadow: 4px 4px 16px rgba(17, 17, 17, 0.2);
    border: 1px solid var(--gray-900);
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    padding: var(--padding-base) var(--padding-base) var(--padding-xs);
    gap: var(--gap-base);
    font-size: var(--font-size-base);
    z-index: 99;
    margin: 0 16px;
    position: absolute;
    width: calc(100% - 36px);
    margin-top: -20px;
  }
  .container,
  .container1 {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
  }
  .container1 {
    align-self: stretch;
    width: 146px;
  }
  .container {
    gap: var(--gap-base);
    width: 100%;
  }
  .checkboxItem,
  .mc {
    display: flex;
    align-items: center;
  }
  .mc {
    flex: 1;
    position: relative;
    line-height: 100%;
    height: 16px;
    color: white;
  }
  .checkboxItem {
    align-self: stretch;
    flex-direction: row;
    justify-content: flex-start;
    gap: var(--gap-5xs);
  }
  .checkbox {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border: 1px solid var(--color-white);
    box-sizing: border-box;
  }
  .checkboxCheckedChild {
    position: absolute;
    height: 47.24%;
    width: 68.9%;
    top: 29.04%;
    right: 14.54%;
    bottom: 23.72%;
    left: 16.56%;
    max-width: 100%;
    overflow: hidden;
    max-height: 100%;
  }
  .checkboxChecked {
    position: relative;
    background-color: var(--color-white);
    width: 16px;
    height: 16px;
  }
  .checkboxItemList {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: var(--gap-xs);
  }

  .buttons1,
  .div25,
  .div27 {
    display: flex;
    flex-direction: row;
  }
  .div27 {
    background-color: var(--color-white);
    align-items: flex-end;
    justify-content: center;
    color: var(--gray-900);
    cursor: pointer;
  }
  .buttons1 {
    align-items: center;
    justify-content: flex-end;
    gap: var(--gap-5xs);
    text-align: center;
    font-size: var(--font-size-smi);
  }
  .copyright2013Container,
  .div12 {
    position: relative;
    line-height: 100%;
    border: 1px solid var(--color-white);
  }
  .copyright2013Container {
    button {
      border: none;
      background-color: transparent;
      padding: 0;
      padding: var(--padding-7xs) var(--padding-xs);
    }
  }
  .div30 {
    color: #6f6f6f;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    margin-left: 20px;
    margin-bottom: 8px;

    .span4 {
      color: #111;
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 100%;
    }
  }
  .membersProfileList {
    padding: 0 20px 60px 20px;
    display: grid;
    gap: 8px;
    grid-template-columns: repeat(2, 1fr);
    width: 100%;
    a {
      text-decoration: none;
      color: var(--color-white);
    }
  }
  .membersProfileWrapper {
    width: 100%;
    aspect-ratio: 3 / 4;
    overflow: hidden;
    border: none;
  }
  .membersProfileImage {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top;
  }
  .membersProfileNameWrapper {
    height: 80px;
    margin-top: -80px;
    padding-left: 20px;
    padding-top: 36px;
    background: linear-gradient(180deg, rgba(17, 17, 17, 0) 0%, rgba(17, 17, 17, 0.8) 100%);
    position: relative;
    .membersProfileName {
      color: #fff;
      font-family: Pretendard;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 100%;
      margin-bottom: 4px;
    }
    .membersProfileEnName {
      color: var(--gray-100, #fafafa);
      font-family: Montserrat;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 100%;
    }
  }
}
